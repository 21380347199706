import React from "react"
import { useRouter } from "next/router"
import { Button } from "antd"
import { useAppDispatch } from "hooks/reduxHooks"
import { layoutLang } from "utils/language/layout"
import { favouritesReducerActions } from "store/reducers/favouritesReducer"
import MetroItems from "pages/apartments/components/mobile-filter/metro-complex/MetroItems"

interface IProps {
  setValue: React.Dispatch<React.SetStateAction<string>>
  value: string
}

const MetroSearch: React.FC<IProps> = ({ value }) => {
  const dispatch = useAppDispatch()
  const { locale } = useRouter()
  const { setOpenRegionSelect } = favouritesReducerActions

  return (
    <>
      <MetroItems value={value} />
      <div
        className="fixed h-[90px] bottom-0 w-full rounded-t-2xl bg-white right-0 flex items-center justify-center p-4 pb-7"
        style={{ boxShadow: " 0px -4px 9.8px 0px #0000001A" }}
      >
        <Button
          type="primary"
          className="w-full h-[46px] rounded-xl"
          onClick={() =>
            dispatch(setOpenRegionSelect({ type: undefined, value: false }))
          }
        >
          {layoutLang[String(locale)]["Saqlash"]}
        </Button>
      </div>
    </>
  )
}

export default MetroSearch
