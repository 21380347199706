import React from "react"

const MetroIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75002 0.278336C2.57438 0.278336 1.57952e-05 2.8527 1.57952e-05 6.02834V11.1999C1.57952e-05 12.9586 0.955735 14.494 2.37613 15.3151L1.82509 16.6927C1.67126 17.0773 1.85832 17.5138 2.24291 17.6676C2.62749 17.8214 3.06397 17.6344 3.21781 17.2498L3.7378 15.9498H4.71301L4.75001 15.9499H9.50446L9.54146 15.9498H10.5166L11.0366 17.2498C11.1905 17.6344 11.6269 17.8214 12.0115 17.6676C12.3961 17.5138 12.5832 17.0773 12.4293 16.6927L11.8783 15.3151C13.2987 14.494 14.2545 12.9586 14.2545 11.1999V6.02834C14.2545 2.8527 11.6801 0.278336 8.50446 0.278336H5.75002ZM9.53507 14.4498C11.3159 14.4334 12.7545 12.9846 12.7545 11.1999V10.2807H1.50002V11.1999C1.50002 12.9847 2.93857 14.4334 4.7194 14.4498H9.53507ZM12.7545 6.02834V8.78073H1.50002V6.02834C1.50002 3.95018 2.99158 2.2204 4.96256 1.85115C5.02158 2.99529 5.96797 3.90484 7.1268 3.90484C8.28569 3.90484 9.23211 2.99521 9.29105 1.85098C11.2625 2.2199 12.7545 3.94988 12.7545 6.02834ZM7.79274 1.77834H6.46086C6.48187 2.12787 6.77199 2.40484 7.1268 2.40484C7.48161 2.40484 7.77173 2.12787 7.79274 1.77834ZM3.58465 11.6569C3.19331 11.6569 2.87607 11.9742 2.87607 12.3655C2.87607 12.7569 3.19331 13.0741 3.58465 13.0741H4.29323C4.68457 13.0741 5.00181 12.7569 5.00181 12.3655C5.00181 11.9742 4.68457 11.6569 4.29323 11.6569H3.58465ZM9.25251 12.3655C9.25251 11.9742 9.56975 11.6569 9.96109 11.6569H10.6697C11.061 11.6569 11.3782 11.9742 11.3782 12.3655C11.3782 12.7569 11.061 13.0741 10.6697 13.0741H9.96109C9.56975 13.0741 9.25251 12.7569 9.25251 12.3655Z"
        fill={props.stroke || "#98A2B3"}
      />
    </svg>
  )
}

export default MetroIcon
