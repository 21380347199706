const MenuTelegramIcon = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0387 0H16.9613C7.59382 0 0 7.59606 0 16.9663V17.0437C0 26.414 7.59382 34.01 16.9613 34.01H17.0387C26.4062 34.01 34 26.414 34 17.0437V16.9663C34 7.59606 26.4062 0 17.0387 0Z"
        fill="#00B0F2"
      />
      <path
        d="M6.43914 16.4241C6.48679 16.4003 6.53446 16.3777 6.58091 16.3562C7.38854 15.9821 8.20688 15.6318 9.02404 15.2814C9.06811 15.2814 9.14194 15.2302 9.18364 15.2135C9.24677 15.1861 9.30991 15.1599 9.37304 15.1325L9.73636 14.9764C9.97936 14.8727 10.2212 14.7691 10.4642 14.6654C10.949 14.4581 11.4338 14.2508 11.9186 14.0422C12.8882 13.6276 13.8591 13.2117 14.8287 12.7971C15.7983 12.3824 16.7691 11.9666 17.7387 11.5519C18.7084 11.1373 19.6792 10.7214 20.6488 10.3068C21.6184 9.89213 22.5893 9.47628 23.5589 9.06163C23.7745 8.96869 24.008 8.83047 24.239 8.78996C24.4332 8.7554 24.6226 8.68868 24.8179 8.65174C25.1884 8.58144 25.597 8.55284 25.952 8.70655C26.0746 8.76017 26.1878 8.83523 26.2819 8.92937C26.7322 9.375 26.669 10.1066 26.5738 10.7334C25.9103 15.1015 25.2468 19.4709 24.5821 23.8391C24.4916 24.4384 24.3677 25.0961 23.8948 25.475C23.4945 25.7956 22.9251 25.8313 22.4308 25.6955C21.9365 25.5584 21.5005 25.2713 21.0729 24.9889C19.2992 23.814 17.5243 22.6392 15.7507 21.4643C15.329 21.1855 14.8597 20.8209 14.8644 20.3145C14.8668 20.0095 15.049 19.7378 15.2349 19.4959C16.7763 17.4846 19.0002 16.1024 20.6548 14.184C20.8882 13.9136 21.0717 13.425 20.7513 13.2689C20.5607 13.176 20.3415 13.3023 20.1676 13.4226C17.9805 14.9419 15.7947 16.4623 13.6077 17.9815C12.8942 18.4771 12.1461 18.9871 11.2861 19.1087C10.5166 19.2183 9.74232 19.0038 8.99783 18.7846C8.37365 18.6011 7.75064 18.4128 7.13003 18.2186C6.80007 18.1161 6.45939 18.0053 6.20448 17.7729C5.94957 17.5406 5.80307 17.1498 5.95673 16.84C6.05322 16.6458 6.24024 16.523 6.43678 16.4229L6.43914 16.4241Z"
        fill="#FEFFFC"
      />
    </svg>
  )
}

export default MenuTelegramIcon
