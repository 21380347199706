const MiniCloseIcon = ({ size = 8 }: { size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.822591 6.62667C0.615234 6.83403 0.611003 7.20219 0.826823 7.41801C1.04688 7.63383 1.41504 7.6296 1.61816 7.42648L4.12337 4.92127L6.62435 7.42225C6.83594 7.63383 7.19987 7.63383 7.41569 7.41801C7.63151 7.19796 7.63151 6.83826 7.41992 6.62667L4.91895 4.1257L7.41992 1.62049C7.63151 1.4089 7.63574 1.04497 7.41569 0.829146C7.19987 0.613326 6.83594 0.613326 6.62435 0.824914L4.12337 3.32589L1.61816 0.824914C1.41504 0.617558 1.04264 0.609094 0.826823 0.829146C0.611003 1.04497 0.615234 1.41736 0.822591 1.62049L3.32357 4.1257L0.822591 6.62667Z"
        fill="#344054"
      />
    </svg>
  )
}

export default MiniCloseIcon
