import { Grid, Tooltip } from "antd"
import RegCheckIcon from "assets/icons/RegCheckIcon"
import Image from "next/image"
import { useRouter } from "next/router"
import React from "react"
import { layoutLang } from "utils/language/layout"
import uysotCoin from "../../../../assets/images/uysot-coin.png"
import { UysotCoinModel } from "pages/main-layout/utils/models/uysotCoinModel"

interface IProps {
  data: UysotCoinModel | undefined
  coinCounts: number | undefined
}

const UysotCoinContent: React.FC<IProps> = ({ coinCounts, data }) => {
  const { locale } = useRouter()
  const { md } = Grid.useBreakpoint()

  return (
    <div className="flex flex-col w-full items-center">
      <div className="flex items-center gap-3">
        <span className="text-[#475467] font-bold text-[56px] leading-[73px]">
          {coinCounts}
        </span>
        <Image width={40} height={44} alt="err" src={uysotCoin} />
      </div>
      {data?.coin_conditions?.some((item) => item?.done) ? (
        <span className="text-[#98A2B3] text-base font-medium">
          {`${layoutLang[String(locale)]["qiymati"]}: ${(
            coinCounts! * data?.coin_price
          )?.toLocaleString("ru")} ${layoutLang[String(locale)]["sum"]}`}
        </span>
      ) : (
        <span className="text-[#98A2B3] text-base font-medium">{`1 coin = ${data?.coin_price?.toLocaleString(
          "ru"
        )} ${layoutLang[String(locale)]["sum"]}`}</span>
      )}
      <div className="w-full flex flex-col gap-3 mt-8">
        {data?.coin_conditions?.map((item, index) => (
          <Tooltip
            overlay={item?.description[locale as keyof typeof item.description]}
            key={item?.id}
          >
            <a
              href={item?.link}
              target="_blank"
              className="flex items-center justify-between bg-[#F2F4F7] p-3 pr-3.5 rounded-2xl cursor-pointer"
            >
              <div className="flex items-center gap-2 w-[76%]">
                {item?.done ? (
                  <div className="md:w-8 min-w-6 md:h-8 min-h-6 flex items-center justify-center rounded-full bg-[#12B76A]">
                    <RegCheckIcon size={md ? 24 : 16} stroke="#fff" />
                  </div>
                ) : (
                  <div className="md:w-8 min-w-6 md:h-8 h-6 flex items-center justify-center rounded-full bg-white text-[#0DC683] text-lg font-medium">
                    {index + 1}
                  </div>
                )}
                <span className="text-[#475467] font-semibold truncate md:text-[15px] text-[13px]">
                  {item?.name[locale as keyof typeof item.name]}
                </span>
              </div>
              <div className="flex items-center justify-end gap-[5px] w-[24%]">
                <span className="text-[#00A389] font-bold md:text-xl text-[17px]">
                  + {item?.amount}
                </span>
                <Image width={26} height={27} alt="err" src={uysotCoin} />
              </div>
            </a>
          </Tooltip>
        ))}
      </div>
      <a
        href="https://docs.google.com/document/d/1ze17hWnxBKRvzkrnYFnondKDqV90XcTgLbSZ177FSys/edit?usp=sharing"
        target="_blank"
        className="w-full text-start mt-3 underline text-[#98A2B3] text-[13px] font-medium cursor-pointer"
      >
        {layoutLang[String(locale)]["foydalanish_shartlari"]}
      </a>
    </div>
  )
}

export default UysotCoinContent
