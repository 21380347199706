import { Input, Spin } from "antd"
import CheckIcon from "assets/icons/CheckIcon"
import Location1Icon from "assets/icons/Location1Icon"
import SearchIcon from "assets/icons/SearchIcon"
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import { useRouter } from "next/router"
import { useGetAllRegion } from "pages/apartments/services/queries"
import React from "react"
import { favouritesReducerActions } from "store/reducers/favouritesReducer"
import { cx } from "utils/cx"
import { layoutLang } from "utils/language/layout"

import styles from "../searchLocation.module.scss"

interface IProps {
  val: string
  type?: "filter"
  handleChangeSearch: (value: string) => void
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const LocationOverlay: React.FC<IProps> = ({
  type,
  handleChangeSearch,
  val,
  setVisible,
}) => {
  const dispatch = useAppDispatch()
  const { locale } = useRouter()
  const { setCurrentRegion } = favouritesReducerActions
  const location = useAppSelector((state) => state.favouritesReducer.location)

  const { data, isFetching } = useGetAllRegion(location?.searchValue)
  return (
    <div
      className={`border border-solid border-[#EAECF0] rounded-xl bg-white w-[360px]  mobileTopFilter`}
      style={{
        boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
      }}
    >
      {type !== "filter" && (
        <Input
          prefix={<SearchIcon color="#98A2B3" size="18" />}
          placeholder={`${layoutLang[String(locale)]["Qidirish"]} ...`}
          value={val}
          onChange={(e) => {
            handleChangeSearch(e?.target?.value)
          }}
          className="h-[43px] rounded-xl  border-t border-solid border-[#EAECF0] shadow-none px-4"
        />
      )}
      <div className="flex flex-col p-3 bg-white min-h-10">
        <div
          className="flex items-center gap-3 px-1 pb-3 border-b border-solid border-[#EAECF0] cursor-pointer"
          onClick={() => {
            dispatch(
              setCurrentRegion({
                type: "location",
                value: undefined,
              })
            )
            setVisible(false)
          }}
        >
          <Location1Icon />
          <div className="flex flex-col">
            <span className="text-[#344054] font-medium">
              {layoutLang[String(locale)]["Butun_Ozbekiston_boylab"]}
            </span>
          </div>
        </div>
        <Spin spinning={isFetching}>
          <div className={cx("max-h-[300px] overflow-y-auto", styles.regions)}>
            {data?.map((item, index) => (
              <div key={index}>
                {item?.district ? (
                  <div
                    className="flex items-center justify-between gap-3 px-1 py-3 border-b border-solid  border-[#EAECF0] cursor-pointer"
                    onClick={() => {
                      dispatch(
                        setCurrentRegion({
                          type: "location",
                          value: item,
                        })
                      )
                      setVisible(false)
                    }}
                  >
                    <div className="flex items-center gap-3">
                      <Location1Icon />
                      <div className="flex flex-col">
                        <span className="text-[#344054] font-medium">
                          {
                            item?.district?.name?.[
                              locale as keyof typeof item.district.name
                            ]
                          }
                        </span>
                        <span className="text-[#667085] text-xs">
                          {
                            item?.district?.city?.name[
                              locale as keyof typeof item.district.city.name
                            ]
                          }
                        </span>
                      </div>
                    </div>
                    {location?.regionValue?.district?.id ===
                      item?.district?.id && <CheckIcon />}
                  </div>
                ) : (
                  <div
                    className="flex items-center justify-between gap-3 px-1 py-3 border-b border-solid border-[#EAECF0] cursor-pointer"
                    onClick={() => {
                      dispatch(
                        setCurrentRegion({
                          type: "location",
                          value: item,
                        })
                      )
                      setVisible(false)
                    }}
                  >
                    <div className="flex items-center gap-3">
                      <Location1Icon />
                      <span>
                        {
                          item?.city?.name[
                            locale as keyof typeof item.city.name
                          ]
                        }
                      </span>
                    </div>
                    {location?.regionValue?.city?.id === item?.city?.id && (
                      <CheckIcon />
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Spin>
      </div>
    </div>
  )
}

export default LocationOverlay
