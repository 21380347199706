function UysotIcon() {
  return (
    <svg
      width="124"
      height="34"
      viewBox="0 0 124 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.89648 30.9747C3.89648 29.3041 5.32203 27.9496 7.08055 27.9496H30.4304C32.189 27.9496 33.6145 29.3041 33.6145 30.9747C33.6145 32.6456 32.189 34.0001 30.4304 34.0001H7.08055C5.32203 34.0001 3.89648 32.6456 3.89648 30.9747Z"
        fill="#00A389"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7608 0.895816C17.9976 -0.298605 20.0025 -0.298605 21.2391 0.895816L37.0726 16.1883C38.3091 17.3828 38.3091 19.3193 37.0726 20.5137C35.8359 21.7082 33.8309 21.7082 32.5942 20.5137L19 7.38388L5.40584 20.5137C4.16918 21.7082 2.16416 21.7082 0.927497 20.5137C-0.309166 19.3193 -0.309166 17.3828 0.927497 16.1883L16.7608 0.895816Z"
        fill="#00A389"
      />
      <path
        d="M23.8516 19.8894C23.8516 22.4845 21.6495 24.5883 18.9331 24.5883C16.2167 24.5883 14.0146 22.4845 14.0146 19.8894C14.0146 17.2944 16.2167 15.1907 18.9331 15.1907C21.6495 15.1907 23.8516 17.2944 23.8516 19.8894Z"
        fill="#FFAB55"
      />
      <path
        d="M55.2747 9.17636V18.9681C55.2747 20.9741 56.4567 22.2862 58.5603 22.2862C60.6531 22.2862 61.8351 20.9741 61.8351 18.9681V9.17636H65.1098V19.2934C65.1098 22.7416 62.5616 25.0947 58.5603 25.0947C54.5482 25.0947 52 22.7416 52 19.2934V9.17636H55.2747Z"
        fill="#00A389"
      />
      <path
        d="M75.4437 24.8236H72.169V18.9789L66.7255 9.17636H70.293L73.7955 15.856H73.8714L77.3847 9.17636H80.898L75.4437 18.9789V24.8236Z"
        fill="#00A389"
      />
      <path
        d="M81.4727 20.3669H84.6282C84.78 21.6573 86.1246 22.5031 87.8596 22.5031C89.6054 22.5031 90.7982 21.6681 90.7982 20.5187C90.7982 19.4994 90.05 18.9355 88.1632 18.5126L86.1246 18.0681C83.2402 17.45 81.8197 15.9861 81.8197 13.6981C81.8197 10.8137 84.3137 8.90527 87.8054 8.90527C91.4488 8.90527 93.7368 10.7812 93.791 13.6114H90.7223C90.6138 12.2885 89.4536 11.486 87.8162 11.486C86.2005 11.486 85.1162 12.2559 85.1162 13.4162C85.1162 14.3813 85.8752 14.9234 87.6752 15.3355L89.5729 15.7367C92.7066 16.409 94.0729 17.7536 94.0729 20.1392C94.0729 23.1754 91.6114 25.0947 87.7295 25.0947C83.9667 25.0947 81.5703 23.3055 81.4727 20.3669Z"
        fill="#00A389"
      />
      <path
        d="M103.149 8.90527C107.779 8.90527 110.696 12.0174 110.696 17.0054C110.696 21.9826 107.779 25.0947 103.149 25.0947C98.5079 25.0947 95.6019 21.9826 95.6019 17.0054C95.6019 12.0174 98.5079 8.90527 103.149 8.90527ZM103.149 11.6378C100.59 11.6378 98.9417 13.7198 98.9417 17.0054C98.9417 20.2801 100.579 22.3513 103.149 22.3513C105.708 22.3513 107.345 20.2801 107.345 17.0054C107.345 13.7198 105.708 11.6378 103.149 11.6378Z"
        fill="#00A389"
      />
      <path
        d="M118.959 24.8236H115.684V11.8764H111.162V9.17636H123.491V11.8764H118.959V24.8236Z"
        fill="#00A389"
      />
    </svg>
  )
}

export default UysotIcon
