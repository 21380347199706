import React, { useEffect, useState } from "react"
import { isAndroid, isMobile } from "react-device-detect"
import { useRouter } from "next/router"

import ArrowRightIcon from "../../../../assets/icons/ArrowRightIcon";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { localeReducerActions } from "../../../../store/reducers/localeReducer";
import { userReducerActions } from "../../../../store/reducers/userReducer";
import { RU, UZ } from "../../../../utils/constants/locales";
import { menuLinks } from "../../utils/constants/menuLinks";

import styles from "./mobileMenu.module.scss"
import { layoutLang } from "../../../../utils/language/layout"
import { cx } from "utils/cx"
import CurrencyIcon from "assets/icons/CurrencyIcon"
import MenuLocationIcon from "assets/icons/MenuLocationIcon"
import LoginModal from "../login-modal/LoginModal"
import YouTubeIcon from "assets/icons/YouTubeIcon"
import LinkedinIcon from "assets/icons/LinkedinIcon"
import MenuTelegramIcon from "assets/icons/MenuTelegramIcon"
import MenuInstagrammIcon from "assets/icons/MenuInstagrammIcon"
import MenuFacebookIcon from "assets/icons/MenuFacebookIcon"
import CloseIcon from "assets/icons/CloseIcon"
import { isBrowser } from "utils/helpers/isBrowser"
import UserIcon from "assets/icons/UserIcon"
import { Button } from "antd"
import { useLogout } from "pages/main-layout/services/mutations"
import { favouritesReducerActions } from "store/reducers/favouritesReducer"
import CustomLink from "common/custom-link"

export const sotialItems = [
  {
    id: 4,
    icon: YouTubeIcon,
    link: "https://www.youtube.com/@uysot_uz",
    platform: null
  },
  {
    id: 5,
    icon: LinkedinIcon,
    link: "https://www.linkedin.com/company/uysot/",
    platform: null
  },
  {
    id: 3,
    icon: MenuFacebookIcon,
    link: "https://www.facebook.com/uysot.uz",
    platform: "facebook",
    shareUrl: "https://www.facebook.com/sharer/sharer.php?u="
  },
  {
    id: 1,
    icon: MenuTelegramIcon,
    link: "https://t.me/uysot_uz",
    platform: "telegram",
    shareUrl: "https://telegram.me/share/url?url="
  },
  {
    id: 2,
    icon: MenuInstagrammIcon,
    link: "https://www.instagram.com/uysot_uz/",
    platform: "instagram",
    shareUrl: "https://www.instagram.com/?url="
  }
];

const MobileMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const { visibleMobileMenu, userAddress, menuIcon } = useAppSelector(state => state.userReducer);
  const [isAppSuggestionCanceled, setIsAppSuggestionCanceled] = useState(false);
  const { currency } = useAppSelector(state => state.localeReducer);

  const { locale } = useRouter();
  const { changeLanguage } = localeReducerActions;
  const { setOpenRegionSelect, setCurrentRegion, setChangeFilterType } = favouritesReducerActions;
  const { location: currentLocation } = useAppSelector(state => state.favouritesReducer);
  const { setVisibleLoginModal, setOpenCurrency, setVisibleMobileMenu, setChangeMenu } = userReducerActions;

  const city_id = currentLocation?.regionValue
    ? currentLocation?.regionValue?.city?.id
      ? currentLocation?.regionValue?.city?.id
      : currentLocation?.regionValue?.district?.city?.id
    : undefined;

  const district_id =
    currentLocation?.regionValue && currentLocation?.regionValue?.district
      ? currentLocation?.regionValue?.district?.id
      : undefined;

  const location =
    currentLocation?.regionValue && currentLocation?.regionValue?.city
      ? currentLocation?.regionValue?.city?.url
      : currentLocation?.regionValue?.district?.city?.url || "gorod-tashkent";

  const isAuth = isBrowser() && localStorage.getItem("is_auth");
  const phone = isBrowser() && localStorage.getItem("phone");
  const logout = useLogout();

  useEffect(() => {
    if (isBrowser() && localStorage.getItem("currentRegion")) {
      dispatch(setCurrentRegion(JSON.parse(localStorage.getItem("currentRegion")!)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // close mobile menu
  const handleCloseMobileMenu = () => {
    dispatch(setVisibleMobileMenu(false));
    dispatch(setChangeMenu(!menuIcon));
  };

  useEffect(() => {
    dispatch(changeLanguage(locale === RU ? RU : UZ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAppSuggestionStatus = () => {
    const appSuggestion = localStorage.getItem("appSuggestionCancelledAt");
    setIsAppSuggestionCanceled(!appSuggestion);
  };

  useEffect(() => {
    window.addEventListener("storage", updateAppSuggestionStatus);

    const intervalId = setInterval(updateAppSuggestionStatus, 500);

    return () => {
      window.removeEventListener("storage", updateAppSuggestionStatus);
      clearInterval(intervalId);
    };
  }, []);

  const handleOpen = () => {
    dispatch(setVisibleLoginModal(true));
  };

  const handleLogout = () => {
    logout.mutate();
    handleCloseMobileMenu();
  };

  return (
    <div
      className={cx(
        `${styles.mobile_menu} ${visibleMobileMenu ? styles.active : ""} ${userAddress ? styles.has_address : ""}`,
        isAndroid && isMobile && isAppSuggestionCanceled ? styles.mobile_menu_with_app_suggestion : ""
      )}
    >
      <div className={`${styles.content} relative bg-[#f2f4f6]`}>
        <div className="relative flex flex-col items-center gap-6 pt-6">
          <div
            className="absolute right-0 top-[-2px] flex h-[30px] w-[30px] items-center justify-center rounded-full bg-white"
            onClick={handleCloseMobileMenu}
          >
            <CloseIcon />
          </div>
          {isAuth === "true" ? (
            <div className="flex flex-col items-center gap-4">
              <UserIcon />
              <span className="text-lg font-semibold text-[#344054]">{phone}</span>
            </div>
          ) : (
            <div className="w-full items-center gap-7 flex flex-col">
              <div className="flex items-center justify-center relative w-full pt-5">
                <span className="text-[#2C2C2C] font-bold text-xl">
                  {layoutLang[String(locale)]["Profilingizga kiring"]}
                </span>
              </div>
              <div
                className="flex h-[46px] w-full cursor-pointer items-center justify-center rounded-xl bg-[#0DC683] py-[14px] text-[15px] font-semibold text-white"
                onClick={handleOpen}
              >
                {layoutLang[String(locale)]["Kirish"]}
              </div>
            </div>
          )}
          <div className="flex w-full items-center gap-2">
            <div
              className="flex w-[40%] cursor-pointer items-center justify-between rounded-xl bg-white p-3"
              onClick={() => dispatch(setOpenCurrency(true))}
            >
              <div className="flex flex-col gap-0.5">
                <p className="text-base font-medium text-[#344054]">{layoutLang[String(locale)]["Valyuta"]}</p>
                <span className="text-sm text-[#667085]">{currency?.toUpperCase()}</span>
              </div>
              <CurrencyIcon />
            </div>
            <div
              className="flex h-[70px] w-[60%] cursor-pointer items-center justify-between rounded-xl bg-white p-3"
              onClick={() => dispatch(setOpenRegionSelect({ type: "location", value: true }))}
            >
              <div className="flex flex-col gap-0.5">
                {currentLocation?.regionValue?.city || currentLocation?.regionValue?.district ? (
                  !currentLocation?.regionValue?.city ? (
                    <div>
                      <p className="text-base font-medium text-[#344054]">
                        {
                          currentLocation?.regionValue?.district?.name[
                            locale as keyof typeof currentLocation.regionValue.district.name
                          ]
                        }
                      </p>
                      <span className="text-sm text-[#667085]">
                        {
                          currentLocation?.regionValue?.district?.city?.name[
                            locale as keyof typeof currentLocation.regionValue.district.city.name
                          ]
                        }
                      </span>
                    </div>
                  ) : (
                    <div className="text-base font-medium text-[#344054]">
                      {
                        currentLocation?.regionValue?.city?.name[
                          locale as keyof typeof currentLocation.regionValue.city.name
                        ]
                      }
                    </div>
                  )
                ) : (
                  <div className="text-base font-medium text-[#344054]">{layoutLang[String(locale)]["mintaqa"]}</div>
                )}
              </div>
              {currentLocation?.regionValue ? (
                <div
                  className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-[#D0D5DD]"
                  onClick={e => {
                    e.stopPropagation();
                    dispatch(setCurrentRegion({ type: "location", value: { city: null, district: null } }));
                    localStorage.removeItem("currentRegion");
                  }}
                >
                  <CloseIcon size={"16"} />
                </div>
              ) : (
                <MenuLocationIcon />
              )}
            </div>
          </div>
        </div>

        <div
          className={`${styles.content_body} ${
            visibleMobileMenu ? styles.active : ""
          } overflow-hidden rounded-xl bg-white p-3 pb-0`}
        >
          <nav>
            <ul className={styles.menu}>
              {menuLinks(location, city_id, district_id).map((item, index) => (
                <li key={index}>
                  <CustomLink 
                    href={item.path} 
                    onClick={() => {
                        handleCloseMobileMenu()
                        item?.path?.includes("kvartiri")
                          ? dispatch(setChangeFilterType("apartment"))
                          : item?.path?.includes("novostroyki")
                            ? dispatch(setChangeFilterType("complex"))
                            : ""
                      }}>
                    <div className="flex justify-between items-center w-full">                     
                       <span>
                        {item.label === "EXPO 2024"
                          ? item.label
                          : layoutLang[String(locale) as any][item.label]}
                      </span>
                      <ArrowRightIcon />
                    </div>
                  </CustomLink>
                </li>
              ))}
              {isAuth === "true" && (
                <CustomLink href={"/bonuses"} onClick={handleCloseMobileMenu}>
                  <div className="flex justify-between items-center w-full">
                    <span>{layoutLang[String(locale)]["Bonuslar"]}</span>
                    <ArrowRightIcon />
                  </div>
                </CustomLink>
              )}
            </ul>
          </nav>
        </div>

        <div className="flex flex-col gap-3 rounded-xl bg-white p-3">
          <span className="text-sm font-medium text-[#344054]">{layoutLang[String(locale)]["Ijtimoiy tarmoqlar"]}</span>
          <div className="flex items-center justify-between">
            {sotialItems?.map((item, index) => (
              <CustomLink
                className="w-[58px] h-[58px] bg-[#F2F4F7] rounded-full flex items-center justify-center"
                href={item?.link}
                target="_blank"
                key={index}
              >
                {item?.icon()}
              </CustomLink>
            ))}
          </div>
        </div>
        {isAuth === "true" && (
          <Button
            className="h-[46px] rounded-xl border-none font-semibold !text-[#344054]"
            onClick={handleLogout}
            loading={logout.isLoading}
          >
            {layoutLang[String(locale)]["Chiqish"]}
          </Button>
        )}
      </div>
      <LoginModal />
    </div>
  );
};

export default MobileMenu;
