const DocumentsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1667 1.8417C12.825 1.50003 12.2333 1.73337 12.2333 2.20837V5.1167C12.2333 6.33337 13.2667 7.3417 14.525 7.3417C15.3167 7.35003 16.4167 7.35003 17.3583 7.35003C17.8333 7.35003 18.0833 6.7917 17.75 6.45837C16.55 5.25003 14.4 3.07503 13.1667 1.8417Z"
        fill="white"
      />
      <path
        d="M17.0833 8.49163H14.675C12.7 8.49163 11.0917 6.88329 11.0917 4.90829V2.49996C11.0917 2.04163 10.7167 1.66663 10.2583 1.66663H6.725C4.15833 1.66663 2.08333 3.33329 2.08333 6.30829V13.6916C2.08333 16.6666 4.15833 18.3333 6.725 18.3333H13.275C15.8417 18.3333 17.9167 16.6666 17.9167 13.6916V9.32496C17.9167 8.86663 17.5417 8.49163 17.0833 8.49163ZM9.58333 14.7916H6.25C5.90833 14.7916 5.625 14.5083 5.625 14.1666C5.625 13.825 5.90833 13.5416 6.25 13.5416H9.58333C9.925 13.5416 10.2083 13.825 10.2083 14.1666C10.2083 14.5083 9.925 14.7916 9.58333 14.7916ZM11.25 11.4583H6.25C5.90833 11.4583 5.625 11.175 5.625 10.8333C5.625 10.4916 5.90833 10.2083 6.25 10.2083H11.25C11.5917 10.2083 11.875 10.4916 11.875 10.8333C11.875 11.175 11.5917 11.4583 11.25 11.4583Z"
        fill="white"
      />
    </svg>
  )
}

export default DocumentsIcon
