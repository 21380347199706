"use-client"
import React, { ReactNode, useEffect, useState } from "react"
import { useRouter } from "next/router"
import { isBrowser } from "utils/helpers/isBrowser"
import { useCheckHasToken } from "services/useCheckHasToken"
import { isAndroid, isMobile } from "react-device-detect"

import NonSSRWrapper from "../../common/non-ssr/NonSSRWrapper"
import Footer from "./components/footer/Footer"
import LoginModal from "./components/login-modal/LoginModal"
import MobileAppDownload from "./components/mobile-app-download/MobileAppDownload"
import MobileMenu from "./components/mobile-menu/MobileMenu"

import FooterFilter from "./components/footer-filter/FooterFilter"
import MobileFooterMenu from "./components/mobile-footer-menu/MobileFooterMenu"

import styles from "./index.module.scss"
import LangDropdown from "./components/header/bottom/LangDropdown"
import MenuCurrency from "./components/mobile-menu/menu-currency/MenuCurrency"
import NavigateButton from "./components/navigate-button/NavigateButton"
import RegionCity from "./components/region-city/RegionCity"
import LayoutHeader from "common/layout-header/LayoutHeader"
import UysotCoinMobile from "./components/uysot-coin/uysot-coin-mobile"
import UserFeedbackModal from "./components/user-feedback-modal"

type Props = {
  children: ReactNode;
};

const MainLayout: React.FC<Props> = ({ children }) => {
  const router = useRouter()
  const [isAppSuggestionCanceled, setIsAppSuggestionCanceled] = useState(false)

  const hasToken = isBrowser() ? localStorage.getItem("token") : "";
  const getAnonimUserId = useCheckHasToken();

  useEffect(() => {
    if (!hasToken) {
      getAnonimUserId.mutateAsync().then(data => {
        localStorage.setItem("anonymous_user_id", data?.data?.anonymous_user_id);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasToken]);

  const updateAppSuggestionStatus = () => {
    const appSuggestion = localStorage.getItem("appSuggestionCancelledAt");
    setIsAppSuggestionCanceled(!appSuggestion);
  };

  useEffect(() => {
    window.addEventListener("storage", updateAppSuggestionStatus);

    const intervalId = setInterval(updateAppSuggestionStatus, 500);

    return () => {
      window.removeEventListener("storage", updateAppSuggestionStatus);
      clearInterval(intervalId);
    };
  }, []);

  const inNavigateBtn =
    (router.pathname.includes("karta") ||
      router.pathname.includes("kvartiri") ||
      router.pathname.includes("novostroyki")) &&
    !router?.query?.apartmentId &&
    !router?.query?.id;


  return (
    <>
      <div className={styles.layout} id="layout">

        <LayoutHeader />

        <NonSSRWrapper>
          <MobileMenu />
        </NonSSRWrapper>
        <main
          className={
            isAppSuggestionCanceled &&
            !router?.query?.id &&
            !router?.query?.apartmentId &&
            !router?.query?.builderId &&
            isAndroid &&
            isMobile
              ? styles.layout_children_with_app_suggestion
              : styles.layout_children
          }
        >
          {children}
        </main>

        <div className="removed_item">
          {/* <Notification /> */}
          <NonSSRWrapper>
            <LangDropdown />
            <div className="mobile_only">
              <UysotCoinMobile />
            </div>
          </NonSSRWrapper>
          <NonSSRWrapper>
            <MenuCurrency />
          </NonSSRWrapper>
          <FooterFilter />
          <MobileAppDownload />
          <Footer />
          {inNavigateBtn && (
            <NonSSRWrapper>
              <div className="mobile_only">
                <NavigateButton />
              </div>
            </NonSSRWrapper>
          )}
          <NonSSRWrapper>
            <MobileFooterMenu />
          </NonSSRWrapper>
        </div>
      </div>
      <LoginModal />
      <NonSSRWrapper>
        <RegionCity />
      </NonSSRWrapper>
      <NonSSRWrapper>
        <UserFeedbackModal />
      </NonSSRWrapper>
    </>
  );
};

export default MainLayout;
