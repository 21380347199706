import { DehydratedState, Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider } from "antd";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { AbstractIntlMessages, NextIntlClientProvider } from "next-intl";
import { Router, useRouter } from "next/router";
import { useEffect, useState } from "react";
import { wrapper } from "../src/store/store";
import { colors } from "../src/utils/constants/colors";
import { Provider } from "react-redux";
import { Expo } from "../src/app";
import Head from "next/head";
import Script from "next/script";
import NProgress from "nprogress";
import ReactGA from "react-ga4";
import CallBackModal from "../src/pages/app/components/call-back-modal/CallBackModal";
import MainLayout from "../src/pages/main-layout/Index";
import Quiz from "app/quiz/Quiz";
import AppWrapper from "../src/common/app-wrapper/AppWrapper";

import type { AppProps } from "next/app";

import * as process from "process";

import "antd/dist/antd.variable.min.css";
import "nprogress/nprogress.css";
import "swiper/css";
import "../src/styles/Common.scss";
import "../src/styles/Main.scss";

type pageProps = {
  pageProps: {
    messages: AbstractIntlMessages | undefined;
    dehydratedState: DehydratedState;
  };
};

ConfigProvider.config({
  theme: {
    primaryColor: colors.PRIMARY_COLOR
  }
});

NProgress.configure({
  showSpinner: false
});

ReactGA.initialize("G-16JG6T4477");

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function MyApp({ Component, pageProps, ...rest }: AppProps & pageProps) {
  const { store } = wrapper.useWrappedStore(rest);
  const router = useRouter();
  const [queryClient] = useState(() => new QueryClient());

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // @ts-ignore
      import("react-facebook-pixel")
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID as string)
          ReactPixel.pageView()

          router.events.on("routeChangeComplete", () => {
            ReactPixel.pageView();
          });
        });
    }
  }, [router.events]);

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="facebook-domain-verification" content="y55ne5u8vg73mp2132esibsvq2xkq5" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link rel="icon" href="/favicon.ico" sizes="32x32" />
        <meta name="google-site-verification" content="Yr7IJZKT7hINItRFnXtinouEzcBB5UOmwfwS4_Br3e8" />
        {/* <- TEST */}
        <meta name="google-site-verification" content="tmsev8EcNQwWbLOBj2-hJjgucZNIpyZpc5W4VxeQn_E" />
        <link
          rel="apple-touch-icon"
          href="https://drive.google.com/file/d/1BIsE-EUq5ODptGlJI5LQr5jc8QUHiel0/view?usp=sharing"
          sizes="32x32"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="https://drive.google.com/file/d/1lWpGF2xTV5RmBI1J1ctiK3JsnPvTKwsx/view?usp=sharing"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="https://drive.google.com/file/d/1xTLwmy83mD_fR-2KP0V3lp1xVAQKbTSJ/view?usp=sharing"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="https://drive.google.com/file/d/13n3mvNyu_rrBqFhfRgonqXVhSvsOfpdP/view?usp=sharing"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://drive.google.com/file/d/1EseSCBJBMQR9QoBIZHHBQ3Ee28JqGED8/view?usp=sharing"
        />
        <meta name="yandex-verification" content="7dc0f07b55c8f23d" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta
          name="apple-itunes-app"
          content="app-id=6451233026, app-argument=https://apps.apple.com/us/app/uysot-yangi-uylar-bozori/id6451233026?itsct=apps_box_link&itscg=30200"
        />
        <meta
          name="google-play-app"
          content="app-id=uz.uysot.market,  app-arguments=https://play.google.com/store/apps/details?id=uz.uysot.marketplace&utm_source=uysot_uz&utm_campaign=footer_link&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        />
        <meta name="smartbanner:title" content="Uysot" />
        <meta name="smartbanner:author" content="SOFTEX" />
        <meta name="smartbanner:button" content="Install App" />
        <meta name="smartbanner:price" content="FREE" />
        <meta
          name="smartbanner:icon-apple"
          content="https://drive.google.com/file/d/1BIsE-EUq5ODptGlJI5LQr5jc8QUHiel0/view?usp=sharing"
        />
        <meta
          name="smartbanner:icon-google"
          content="https://drive.google.com/file/d/1BIsE-EUq5ODptGlJI5LQr5jc8QUHiel0/view?usp=sharing"
        />
        <meta content="Uysot" property="og:site_name" />
        <meta name="smartbanner:enabled-platforms" content="android,ios" />
        <meta
          name="smartbanner:button-url-android"
          content="https://play.google.com/store/apps/details?id=uz.uysot.marketplace&utm_source=uysot_uz&utm_campaign=footer_link&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        />
        <meta
          name="smartbanner:button-url-apple"
          content="https://apps.apple.com/us/app/uysot-yangi-uylar-bozori/id6451233026?itsct=apps_box_link&itscg=30200"
        />
        {router.pathname?.includes("expo") && (
          <>
            <title>UYSOT | EXPO</title>
          </>
        )}
      </Head>
      <NextIntlClientProvider messages={pageProps.messages} locale={router.locale}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            {router.pathname?.includes("expo") ? (
              <Expo>
                <Component {...pageProps} />
              </Expo>
            ) : router?.pathname?.includes("quiz") ? (
              <Provider store={store}>
                <Quiz />
                <CallBackModal />
              </Provider>
            ) : (
              <Provider store={store}>
                <MainLayout>
                  <Component {...pageProps} />
                </MainLayout>
                <SpeedInsights />
                <CallBackModal />
                <ReactQueryDevtools initialIsOpen={false} />
                <AppWrapper />
              </Provider>
            )}
          </Hydrate>
        </QueryClientProvider>
      </NextIntlClientProvider>
      <Script
        id="uysot_bot"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
                (function(d, t) {
                  var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
                  v.onload = function() {
                    window.voiceflow.chat.load({
                      verify: { projectID: '674aa827edf15683a6d55f50' },
                      url: 'https://general-runtime.voiceflow.com',
                      versionID: 'production'
                    });
                  }
                  v.src = "https://cdn.voiceflow.com/widget/bundle.mjs"; v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
                })(document, 'script');
              `,
        }}
      />
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-L2716XRZ85"
      ></Script>
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-L2716XRZ85"
      ></Script>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=AW-10943131584"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'AW-10943131584');
        `}
      </Script>
      {process.env.NODE_ENV === "production" && (
        <>
          <Script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2529825371829379"
            crossOrigin="anonymous"
          ></Script>
          <Script id="yandex-metrics" type="text/javascript">
            {`
              (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
              m[i].l=1*new Date();
              for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
              k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
              (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

              ym(92795399, "init", {
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
                webvisor:true,
                ecommerce:"dataLayer"
              });
            `}
          </Script>
        </>
      )}
    </>
  );
}

export default MyApp;
