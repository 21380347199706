import { Grid } from 'antd'
import { useRouter } from 'next/router'
import Header from 'pages/main-layout/components/header/Header'
import React, { useEffect, useState } from 'react'

import styles from './layoutHeader.module.scss'
import { isBrowser } from 'utils/helpers/isBrowser'
import FilterDesktop from 'common/filter-desktop/FilterDesktop'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { favouritesReducerActions } from 'store/reducers/favouritesReducer'

const LayoutHeader = () => {
  const [position, setPosition] = useState(isBrowser() ? window.pageYOffset : 0)
  const { md, lg, sm, xs } = Grid.useBreakpoint()
  const router = useRouter()
  const dispatch = useAppDispatch()
  const filterVisible = useAppSelector((state) => state.favouritesReducer.filterVisible)
  const { setFilterVisible } = favouritesReducerActions

  const isPad =
    router?.pathname === "/[location]/kvartiri" ||
    router?.pathname === "/[location]/novostroyki"

  useEffect(() => {
    const handleScroll = () => {
      const moving = window.pageYOffset

      dispatch(setFilterVisible(position > moving));
      setPosition(moving)
    };
    window.addEventListener("scroll", handleScroll);
    return (() => {
      window.removeEventListener("scroll", handleScroll);
    })
  })



  const header = () => {
    if (lg || md || sm || xs) {
      if (
        xs &&
        (router?.query?.id ||
          router?.query?.builderId ||
          router?.query?.apartmentId)
      ) {
        return null
      }
      return (
        <header className={`${styles.header} ${filterVisible ? styles.visible : styles.hidden}`}>
          <Header />
        </header>
      )
    }

    return null
  }

  const headerTop = () => {
    if (lg || md || sm || xs) {
      if (
        xs &&
        (router?.query?.id ||
          router?.query?.builderId ||
          router?.query?.apartmentId)
      ) {
        return null
      }
      return (
        <div
          className={`${styles.distance} ${isPad ? "md:pb-[96px] pb-[70px]" : "pb-[70px]"} removed_item`}
        />
      )
    }

    return null
  }


  return (
    <>
      {headerTop()}
      <div className='w-full bg-white'>
        {header()}
      </div>
      <div className={`desctop_only transition-all duration-[400ms] sticky ${filterVisible ? "top-[70px]" : "top-0"} z-[1000]`} style={{
        filter: "drop-shadow(0 -2px 72px rgba(0,0,0,.05)) drop-shadow(0 -.250431px 9.01552px rgba(0,0,0,.025))"
      }}>
        {(router.pathname === "/[location]/novostroyki" ||
          router.pathname === "/[location]/kvartiri" ||
          router.pathname === "/karta") && <FilterDesktop />}
      </div>
    </>
  )
}

export default LayoutHeader
