import ExpoLogoIcon from "assets/icons/ExpoLogoIcon"
import FacebookMiniIcon from "assets/icons/FacebookMiniIcon"
import InstagramMiniIcon from "assets/icons/InstagramMiniIcon"
import TelegramMiniIcon from "assets/icons/TelegramMiniIcon"
import CustomLink from "common/custom-link"
import React from "react"

const Footer = () => {
  return (
    <div className="bg-[#1B815F] py-[40px] flex justify-center text-white">
      <div className="max-width flex justify-between md:flex-row flex-col w-full items-center md:items-start md:gap-0 gap-[32px]">
        <div className="md:block flex flex-col items-center">
          <ExpoLogoIcon />
          <p className="uppercase mt-[48px] text-[14px] leading-[20.75px] tracking-[4px]">
            uysot® barcha huquqlar himoyalangan
          </p>
        </div>
        <div className="flex md:gap-[78px] gap-[32px] md:flex-row flex-col items-center md:items-start">
          <CustomLink
            href={"tel:998935226888"}
            className="text-[24px] font-[300] leading-[35.57px] tracking-[3px]"
          >
            +998 (93) 522 68 88
          </CustomLink>
          <CustomLink
            href={"mail:admin@uysot.uz"}
            className="text-[24px] font-[300] leading-[35.57px] tracking-[3px]"
          >
            admin@uysot.uz
          </CustomLink>
          <div className="flex flex-col items-center">
            <div className="flex gap-[32px]">
              <CustomLink href={"https://t.me/uysot_uz"} target="_blank">
                <TelegramMiniIcon />
              </CustomLink>
              <CustomLink
                href={"https://www.instagram.com/uysot_uz/"}
                target="_blank"
                rel="noopener"
              >
                <InstagramMiniIcon />
              </CustomLink>
              <CustomLink href={"https://www.facebook.com/uysot.uz/"} target="_blank">
                <FacebookMiniIcon />
              </CustomLink>
            </div>
            <p className="uppercase mt-[48px] text-[14px] leading-[20.75px] font-[400] tracking-[4px]">
              uysot.UZ • 2024
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
