import $api from "services/RequestService"
import { endpoints } from "pages/main-layout/utils/constants/apis"

export async function fetchFavorites() {
  const res = await $api.get(endpoints.FAVOURITES)
  return res.data
}

export const fetchUysotCoin = async () => {
  const res = await $api.get(endpoints.UYSOT_COIN)
  return res.data.data
}
