const LinkedinIcon = () => {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2387 0H17.1612C7.79379 0 0.199951 7.59606 0.199951 16.9663V17.0437C0.199951 26.4139 7.79379 34.01 17.1612 34.01H17.2387C26.6061 34.01 34.2 26.4139 34.2 17.0437V16.9663C34.2 7.59606 26.6061 0 17.2387 0Z"
        fill="#007EBB"
      />
      <path
        d="M8.30807 11.3063C7.86019 10.8904 7.63745 10.3757 7.63745 9.76325C7.63745 9.1508 7.86138 8.61342 8.30807 8.19638C8.75596 7.78054 9.3325 7.57202 10.0389 7.57202C10.7452 7.57202 11.2992 7.78054 11.7458 8.19638C12.1937 8.61223 12.4165 9.13531 12.4165 9.76325C12.4165 10.3912 12.1925 10.8904 11.7458 11.3063C11.298 11.7221 10.7298 11.9307 10.0389 11.9307C9.34798 11.9307 8.75596 11.7221 8.30807 11.3063ZM12.0401 13.6917V26.4412H8.01266V13.6917H12.0401Z"
        fill="#FEFFFC"
      />
      <path
        d="M25.4473 14.9518C26.3252 15.905 26.7635 17.2133 26.7635 18.8791V26.2166H22.9387V19.3962C22.9387 18.5562 22.7207 17.9033 22.2859 17.4386C21.8511 16.9739 21.265 16.7403 20.5313 16.7403C19.7975 16.7403 19.2114 16.9727 18.7766 17.4386C18.3419 17.9033 18.1239 18.5562 18.1239 19.3962V26.2166H14.2764V13.6566H18.1239V15.3224C18.5134 14.7671 19.0387 14.3286 19.6986 14.0057C20.3586 13.6828 21.1007 13.522 21.9262 13.522C23.3961 13.522 24.5706 13.9986 25.4473 14.9518Z"
        fill="#FEFFFC"
      />
    </svg>
  )
}

export default LinkedinIcon
