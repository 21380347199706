import { Dropdown, Input } from "antd"
import ArrowBottom from "assets/icons/ArrowBottom"
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import { useRouter } from "next/router"

import React, { useEffect, useState } from "react"
import { favouritesReducerActions } from "store/reducers/favouritesReducer"
import { layoutLang } from "utils/language/layout"
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper"

import LocationOverlay from "./location-overlay/LocationOverlay"

interface IProps {
  type?: "filter"
}

const SearchLocation: React.FC<IProps> = ({ type }) => {
  const dispatch = useAppDispatch()
  const { locale } = useRouter()
  const [visible, setVisible] = useState(false)
  const [time, setTime] = useState<any>()
  const [val, setVal] = useState("")
  const { setOpenRegionSelect, setSearchValue } = favouritesReducerActions
  const location = useAppSelector(
    (state) => state.favouritesReducer?.location?.regionValue
  )

  const handleChangeSearch = (value: string) => {
    setVal(value)
    clearTimeout(time)
    if (value) {
      setTime(
        setTimeout(() => {
          dispatch(setSearchValue(value))
        }, 800)
      )
    } else {
      dispatch(setSearchValue(value))
    }
  }

  const locationName =
    location?.city || location?.district
      ? location?.city
        ? location?.city?.name[locale as keyof typeof location.city.name]
        : `${location?.district?.name[
        locale as keyof typeof location.district.name
        ]
        }`
      : layoutLang[String(locale)]["Butun_Ozbekiston_boylab"]

  useEffect(() => {
    setVal(locationName!)
  }, [locationName])


  return (
    <div className="cursor-pointer flex-1">
      <div className="md:block hidden">
        <Dropdown
          overlay={
            <LocationOverlay
              val={val}
              type={type}
              setVisible={setVisible}
              handleChangeSearch={handleChangeSearch}
            />
          }
          placement="bottom"
          trigger={["click", "hover"]}
          mouseEnterDelay={1000}
          mouseLeaveDelay={0.3}
          open={visible}
          onOpenChange={() => setVisible(!visible)}
        >
          {type === "filter" ? (
            <Input
              placeholder={`${layoutLang[String(locale)]["Qidirish"]} ...`}
              onChange={(e) => {
                handleChangeSearch(e?.target?.value)
              }}
              value={val}
              className="h-[43px] rounded-xl shadow-none px-4"
            />
          ) : (
            <div className={`flex items-center gap-1 w-max md:w-auto`}>
              <span className="text-[#1E90FF] leading-[19px] font-medium truncate w-[95%]">
                {locationName}
              </span>
              <ArrowBottom stroke="#1E90FF" />
            </div>
          )}
        </Dropdown>
      </div>
      <NonSSRWrapper>
        <div
          className={`md:hidden flex items-center gap-1 w-max md:w-auto`}
          onClick={() =>
            dispatch(setOpenRegionSelect({ type: "hot_location", value: true }))
          }
        >
          <span className="text-[#1E90FF] leading-[19px] font-medium truncate w-[95%]">
            {locationName}
          </span>
          <ArrowBottom stroke="#1E90FF" />
        </div>
      </NonSSRWrapper>
    </div>
  )
}

export default SearchLocation
