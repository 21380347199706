import { Tooltip } from "antd"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import React from "react"
import ReactGA from "react-ga4"

import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import ComplexHeart from "../../../pages/heart/components/complex/ComplexHeart";
import { userReducerActions } from "../../../store/reducers/userReducer";
import { gaActions, gaCategories } from "../../../utils/constants/ga";
import { RU, UZS } from "../../../utils/constants/locales";
import { rootPaths } from "../../../utils/constants/rootPaths";
import { viewTypes } from "../../../utils/constants/viewTypes";
import { isEmptyArr } from "../../../utils/helpers/isEmptyArr";
import { makeUrl } from "../../../utils/helpers/makeUrl";
import { NewResidentalComplexModel } from "../../../utils/models/residentialComplexModel";
import NonSSRWrapper from "../../non-ssr/NonSSRWrapper";
import CommonCardTop from "../top/CommonCardTop";

import styles from "../commonCard.module.scss";

import { useRouter } from "next/router";

import PhoneIcon from "../../../assets/icons/PhoneIcon";
import Placements from "../../../pages/detailed-residential-complex/components/placements/Placements";
import { calcUsdValue } from "../../../utils/helpers/calcUsdValue";
import { layoutLang } from "../../../utils/language/layout";

import DocumentsIcon from "assets/icons/DocumentsIcon";
import BuildingIcon from "assets/icons/BuildingIcon";
import BadgeIcon from "assets/icons/BadgeIcon";
import Lottie from "lottie-react";

import animationData from "../../../assets/lottie/Animation.json";
import { cx } from "utils/cx";
import { isEmptyObject } from "utils/helpers/isEmptyObject";
import CustomLink from "common/custom-link";

dayjs.extend(advancedFormat);

type Props = {
  viewType: viewTypes;
  data: NewResidentalComplexModel;
  isMap?: boolean;
};

const ComplexCard: React.FC<Props> = ({ data, viewType, isMap }) => {
  const dispatch = useAppDispatch();
  const { locale, pathname } = useRouter();
  const { setCallBackModal } = userReducerActions;
  const { currency, usdValue } = useAppSelector(state => state.localeReducer);

  // price cont
  const priceCont = () => {
    const totalPrice = data?.price_permission ? data?.min_total_price : null;

    // checking
    if (!totalPrice) {
      return (
        <div className={styles.price_cont}>
          <p>{layoutLang[String(locale)]["Договорная"]}</p>
        </div>
      );
    }

    return (
      <div className={styles.price_cont}>
        <p>
          {totalPrice
            ? locale === "uz"
              ? `${Math.ceil(calcUsdValue(totalPrice, usdValue, currency))?.toLocaleString(RU)} ${currency === UZS ? "so'mdan" : "$ dan"
              }`
              : `от ${Math.ceil(calcUsdValue(totalPrice, usdValue, currency))?.toLocaleString(
                RU
              )} ${currency === UZS ? "сум" : "$"}`
            : layoutLang[String(locale)]["Договорная"]}
        </p>
      </div>
    );
  };

  // coll back
  const handleCallBack = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      setCallBackModal({
        visible: true,
        type: "complex",
        id: data?.id
      })
    );
    ReactGA.event({
      action: gaActions.CALL_ME,
      category: gaCategories.PHONE_NUMBER
    });
  };

  return (
    <CustomLink
      href={`/${data?.district?.city?.url}${rootPaths.RESIDENTIAL_COMPLEXES
        }/${makeUrl(data?.slug || data?.name)}-${data?.id}`}
      target={isMap ? "_blank" : "_self"}
      rel="noopener noreferrer"
    >
      <div
        className={`${styles.complex_card} ${styles[viewType]} ${pathname.includes("zastroyshiki") || pathname.includes("hod-stroitelstva") ? "!w-full md:!w-[390px]" : ""} h-[480px]`}
      >
        <CommonCardTop
          images={data?.image}
          classes={data?.class}
          viewType={viewType}
          is_top={data?.is_top}
          alt={`${layoutLang[String(locale)]["ЖК"]} "${data?.name || data?.name
            }"`}
          is_apartment={false}
        />
        <div className={styles.complex_card_bottom}>
          <NonSSRWrapper>
            <div className="absolute left-3 top-[-3.5rem] z-[1] flex w-auto items-center gap-1.5">
              {data?.availability_construction_document && (
                <Tooltip
                  overlay={layoutLang[String(locale)]["qurulish_hujjatlari"]}
                >
                  <span className="w-8 h-8 rounded-full flex items-center justify-center bg-[#00000080]">
                    <CustomLink
                      href={`/${data?.district?.city?.url}${rootPaths.RESIDENTIAL_COMPLEXES
                        }/${makeUrl(data?.name || data?.name)}-${data?.id
                        }#documents`}
                    >
                      <a className="flex h-full w-full items-center justify-center">
                        <DocumentsIcon />
                      </a>
                    </CustomLink>
                  </span>
                </Tooltip>
              )}
              {data?.availability_construction_process && (
                <Tooltip
                  overlay={layoutLang[String(locale)]["qurulish_jarayoni"]}
                >
                  <span className="w-8 h-8 rounded-full flex items-center justify-center bg-[#00000080]">
                    <CustomLink
                      href={`/${data?.district?.city?.url}${rootPaths.RESIDENTIAL_COMPLEXES
                        }/${makeUrl(data?.name || data?.name)}-${data?.id
                        }#proccess`}
                    >
                      <a className="flex h-full w-full items-center justify-center">
                        <BuildingIcon />
                      </a>
                    </CustomLink>
                  </span>
                </Tooltip>
              )}
              {data?.availability_bonus && (
                <div className="relative flex h-8 w-8 items-center justify-center">
                  <div className="absolute left-[50%] top-[50%] h-full w-full translate-x-[-50%] translate-y-[-50%] rounded-full bg-[#00000080]" />
                  <div className="absolute h-[49px] w-[49px]">
                    <Lottie animationData={animationData} />
                  </div>
                </div>
              )}
            </div>
          </NonSSRWrapper>
          <div className="flex flex-col gap-3">
            {!isEmptyArr(data?.payment_methods) && (
              <div className={styles.top}>
                <div className={styles.tags}>
                  {data?.payment_methods?.map((item, index) => (
                    <div className={styles.tag} key={index}>
                      <span>{locale === RU ? item?.name?.ru : item?.name?.ru}</span>
                    </div>
                  ))}
                </div>
                <div className={styles.like}>
                  <NonSSRWrapper>
                    <ComplexHeart data={data} />
                  </NonSSRWrapper>
                </div>
              </div>
            )}
            <div className={cx(isEmptyArr(data?.payment_methods) && "mt-2", "flex flex-col gap-1")}>
              <div className={cx(styles.name)}>
                <div className={cx(isEmptyArr(data?.payment_methods) && "mb-2")}>
                  <h3>
                    {`${locale === "ru" ? layoutLang[String(locale)]["ЖК"] : ""} "${data?.name}" ${locale === "uz" ? layoutLang[String(locale)]["ЖК"] : ""
                      }`}
                  </h3>
                </div>
                {isEmptyArr(data?.payment_methods) && (
                  <div className={styles.like}>
                    <NonSSRWrapper>
                      <ComplexHeart data={data} />
                    </NonSSRWrapper>
                  </div>
                )}
              </div >
              <p className={styles.address}>
                {locale === RU ? data?.district?.city?.name?.ru : data?.district?.city?.name?.uz}
                {", "}
                {locale === RU ? data?.district?.name?.ru : data?.district?.name?.uz}
              </p>
              {
                data?.nearest_place && !isEmptyObject(data?.nearest_place) && (
                  <Placements type="complex" data={data?.nearest_place} />
                )
              }
            </div >
          </div >
          <div
            className={cx(
              "flex flex-col gap-[14px]",
              data?.nearest_place && !isEmptyObject(data?.nearest_place) && "mt-4"
            )}
          >
            <div className="flex flex-col gap-4">
              {priceCont()}
              <div className="flex items-center gap-2">
                <p className={`${styles.builder} !mb-0 !text-[#1570EF]`}>
                  {data?.brand ? data?.brand?.name : data?.builder?.name}
                </p>
                {data?.brand?.badge ? (
                  <Tooltip overlay={layoutLang[String(locale)]["badge_text"]}>
                    <span>
                      <BadgeIcon />
                    </span>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className={styles.call} onClick={handleCallBack}>
              <PhoneIcon />
              <span>{layoutLang[String(locale)]["Перезвоните мне"]}</span>
            </div>
          </div>
        </div>
      </div>
    </CustomLink>
  )
}

export default ComplexCard;
