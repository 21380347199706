import { useRouter } from "next/router"
import React, { useState } from "react"

import {
  imageLayouts,
  imageLoadings,
  imageObjectFits,
} from "../../../utils/constants/imageAttributes"
import { viewTypes } from "../../../utils/constants/viewTypes"

import styles from "../commonCard.module.scss"
import { layoutLang } from "../../../utils/language/layout"
import { SwiperSlide, Swiper } from "swiper/react"
import { Pagination } from "swiper"

import "swiper/css"
import "swiper/css/pagination"
import RichImage from "common/rich-image/rich-image"
import { RU } from "utils/constants/locales"

type Props = {
  viewType: viewTypes
  images: {
    small: string
    medium: string
    large: string
  }[]
  classes: any
  alt: string
  is_top?: boolean
  is_apartment: boolean
}

const CommonCardTop: React.FC<Props> = ({
  images,
  viewType,
  classes,
  alt,
  is_top,
  is_apartment,
}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const { locale } = useRouter()
  // handle mouse enter
  const handleMouseEnterSlider = (index: number) => {
    setActiveImageIndex(index)
  }
  // handle mouse leave
  const handleMouseLeave = () => {
    setActiveImageIndex(0)
  }

  // top tags container
  const topTagsCont = () => {
    if (viewType === viewTypes.GRID) {
      return (
        <div className={styles.tags}>
          {is_top ? (
            <div className={`${styles.tag} ${styles.tag_primary}`}>
              <span>{layoutLang[String(locale)]?.TOP}</span>
            </div>
          ) : (
            ""
          )}
          {classes ? (
            <div className={styles.tag}>
              {is_apartment ? (
                <span>
                  {locale === RU ? classes?.name_ru : classes?.name_uz}
                </span>
              ) : (
                <span>
                  {locale === RU ? classes?.name?.ru : classes?.name?.uz}
                </span>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      )
    }
    return null
  }

  // image slider container
  const imageSliderCont = () => {
    if (viewType === viewTypes.GRID) {
      return (
        <div className={styles.image_slider}>
          {images?.slice(0, 4)?.map((_, index) => (
            <div
              onMouseEnter={() => handleMouseEnterSlider(index)}
              key={index}
            />
          ))}
        </div>
      )
    }
    return null
  }

  // slider bullets container
  const sliderBulletsCont = () => {
    if (viewType === viewTypes.GRID) {
      return (
        <div className={styles.slider_bullets}>
          {images?.slice(0, 4)?.map((_, index) => (
            <span
              className={index === activeImageIndex ? styles.active : ""}
              key={index}
            />
          ))}
        </div>
      )
    }
    return null
  }

  // count of images
  const countOfImages = (index: number) => {
    if (viewType === viewTypes.LIST) {
      if (index === 3 && images?.length > 4) {
        return (
          <div className={styles.count_of_images}>
            <p>+{images?.length - 4}</p>
          </div>
        )
      }
    }
    return null
  }

  return (
    <div className={styles.complex_card_top} onMouseLeave={handleMouseLeave}>
      <div className={styles.container}>
        {topTagsCont()}
        <div className={styles.image_cont}>
          {images?.slice(0, 4)?.map((item, index) => (
            <div
              className={`${index === activeImageIndex ? styles.active : ""} ${
                styles.image
              } ${styles[`image${index}`]}`}
              key={index}
            >
              <RichImage
                src={
                  activeImageIndex === index
                    ? item.medium
                    : images[activeImageIndex].medium
                }
                layout={imageLayouts.FILL}
                objectFit={imageObjectFits.COVER}
                loading={imageLoadings.LAZY}
                decoding="async"
                alt={alt}
              />
              {countOfImages(index)}
            </div>
          ))}
        </div>
        {imageSliderCont()}
        {sliderBulletsCont()}
      </div>
      <Swiper
        pagination={true}
        modules={[Pagination]}
        className={styles.mySwiper}
      >
        {images?.slice(0, 8)?.map((item, index) => (
          <SwiperSlide key={index}>
            <RichImage
              src={item?.medium}
              layout={imageLayouts.FILL}
              objectFit={imageObjectFits.COVER}
              loading={imageLoadings.LAZY}
              decoding="async"
              alt={alt}
            />
            <div className={styles.mySwiper_card}></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default CommonCardTop
