const QrCodeIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4107_3775)">
        <path d="M100 0H0V100H100V0Z" fill="white" />
        <path
          d="M37.931 6.89648H34.4827V10.3448H37.931V6.89648Z"
          fill="black"
        />
        <path
          d="M41.3793 6.89648H37.931V10.3448H41.3793V6.89648Z"
          fill="black"
        />
        <path
          d="M44.8275 6.89648H41.3793V10.3448H44.8275V6.89648Z"
          fill="black"
        />
        <path
          d="M51.7242 6.89648H48.2759V10.3448H51.7242V6.89648Z"
          fill="black"
        />
        <path
          d="M65.5172 6.89648H62.069V10.3448H65.5172V6.89648Z"
          fill="black"
        />
        <path d="M37.931 10.3447H34.4827V13.793H37.931V10.3447Z" fill="black" />
        <path
          d="M44.8275 10.3447H41.3793V13.793H44.8275V10.3447Z"
          fill="black"
        />
        <path
          d="M48.2759 10.3447H44.8276V13.793H48.2759V10.3447Z"
          fill="black"
        />
        <path
          d="M51.7242 10.3447H48.2759V13.793H51.7242V10.3447Z"
          fill="black"
        />
        <path
          d="M65.5172 10.3447H62.069V13.793H65.5172V10.3447Z"
          fill="black"
        />
        <path d="M41.3793 13.793H37.931V17.2412H41.3793V13.793Z" fill="black" />
        <path
          d="M44.8275 13.793H41.3793V17.2412H44.8275V13.793Z"
          fill="black"
        />
        <path
          d="M51.7242 13.793H48.2759V17.2412H51.7242V13.793Z"
          fill="black"
        />
        <path
          d="M55.1724 13.793H51.7241V17.2412H55.1724V13.793Z"
          fill="black"
        />
        <path
          d="M62.0689 13.793H58.6207V17.2412H62.0689V13.793Z"
          fill="black"
        />
        <path
          d="M37.931 17.2412H34.4827V20.6895H37.931V17.2412Z"
          fill="black"
        />
        <path
          d="M44.8275 17.2412H41.3793V20.6895H44.8275V17.2412Z"
          fill="black"
        />
        <path
          d="M48.2759 17.2412H44.8276V20.6895H48.2759V17.2412Z"
          fill="black"
        />
        <path
          d="M55.1724 17.2412H51.7241V20.6895H55.1724V17.2412Z"
          fill="black"
        />
        <path
          d="M58.6207 17.2412H55.1724V20.6895H58.6207V17.2412Z"
          fill="black"
        />
        <path
          d="M44.8275 20.6897H41.3793V24.138H44.8275V20.6897Z"
          fill="black"
        />
        <path
          d="M55.1724 20.6897H51.7241V24.138H55.1724V20.6897Z"
          fill="black"
        />
        <path
          d="M58.6207 20.6897H55.1724V24.138H58.6207V20.6897Z"
          fill="black"
        />
        <path
          d="M62.0689 20.6897H58.6207V24.138H62.0689V20.6897Z"
          fill="black"
        />
        <path
          d="M41.3793 24.1379H37.931V27.5862H41.3793V24.1379Z"
          fill="black"
        />
        <path
          d="M44.8275 24.1379H41.3793V27.5862H44.8275V24.1379Z"
          fill="black"
        />
        <path
          d="M51.7242 24.1379H48.2759V27.5862H51.7242V24.1379Z"
          fill="black"
        />
        <path
          d="M55.1724 24.1379H51.7241V27.5862H55.1724V24.1379Z"
          fill="black"
        />
        <path
          d="M58.6207 24.1379H55.1724V27.5862H58.6207V24.1379Z"
          fill="black"
        />
        <path
          d="M65.5172 24.1379H62.069V27.5862H65.5172V24.1379Z"
          fill="black"
        />
        <path
          d="M37.931 27.5862H34.4827V31.0345H37.931V27.5862Z"
          fill="black"
        />
        <path
          d="M44.8275 27.5862H41.3793V31.0345H44.8275V27.5862Z"
          fill="black"
        />
        <path
          d="M51.7242 27.5862H48.2759V31.0345H51.7242V27.5862Z"
          fill="black"
        />
        <path
          d="M58.6207 27.5862H55.1724V31.0345H58.6207V27.5862Z"
          fill="black"
        />
        <path
          d="M65.5172 27.5862H62.069V31.0345H65.5172V27.5862Z"
          fill="black"
        />
        <path
          d="M37.931 31.0344H34.4827V34.4827H37.931V31.0344Z"
          fill="black"
        />
        <path
          d="M41.3793 31.0344H37.931V34.4827H41.3793V31.0344Z"
          fill="black"
        />
        <path
          d="M51.7242 31.0344H48.2759V34.4827H51.7242V31.0344Z"
          fill="black"
        />
        <path
          d="M58.6207 31.0344H55.1724V34.4827H58.6207V31.0344Z"
          fill="black"
        />
        <path
          d="M62.0689 31.0344H58.6207V34.4827H62.0689V31.0344Z"
          fill="black"
        />
        <path
          d="M10.3448 34.4827H6.89655V37.9309H10.3448V34.4827Z"
          fill="black"
        />
        <path
          d="M17.2414 34.4827H13.7931V37.9309H17.2414V34.4827Z"
          fill="black"
        />
        <path
          d="M20.6897 34.4827H17.2414V37.9309H20.6897V34.4827Z"
          fill="black"
        />
        <path
          d="M27.5862 34.4827H24.1379V37.9309H27.5862V34.4827Z"
          fill="black"
        />
        <path
          d="M31.0345 34.4827H27.5862V37.9309H31.0345V34.4827Z"
          fill="black"
        />
        <path
          d="M34.4828 34.4827H31.0345V37.9309H34.4828V34.4827Z"
          fill="black"
        />
        <path
          d="M55.1724 34.4827H51.7241V37.9309H55.1724V34.4827Z"
          fill="black"
        />
        <path
          d="M62.0689 34.4827H58.6207V37.9309H62.0689V34.4827Z"
          fill="black"
        />
        <path
          d="M72.4138 34.4827H68.9655V37.9309H72.4138V34.4827Z"
          fill="black"
        />
        <path
          d="M82.7586 34.4827H79.3104V37.9309H82.7586V34.4827Z"
          fill="black"
        />
        <path
          d="M89.6552 34.4827H86.2069V37.9309H89.6552V34.4827Z"
          fill="black"
        />
        <path
          d="M93.1034 34.4827H89.6552V37.9309H93.1034V34.4827Z"
          fill="black"
        />
        <path
          d="M13.7931 37.9309H10.3448V41.3792H13.7931V37.9309Z"
          fill="black"
        />
        <path
          d="M20.6897 37.9309H17.2414V41.3792H20.6897V37.9309Z"
          fill="black"
        />
        <path
          d="M24.1379 37.9309H20.6896V41.3792H24.1379V37.9309Z"
          fill="black"
        />
        <path
          d="M27.5862 37.9309H24.1379V41.3792H27.5862V37.9309Z"
          fill="black"
        />
        <path
          d="M37.931 37.9309H34.4827V41.3792H37.931V37.9309Z"
          fill="black"
        />
        <path
          d="M41.3793 37.9309H37.931V41.3792H41.3793V37.9309Z"
          fill="black"
        />
        <path
          d="M48.2759 37.9309H44.8276V41.3792H48.2759V37.9309Z"
          fill="black"
        />
        <path
          d="M51.7242 37.9309H48.2759V41.3792H51.7242V37.9309Z"
          fill="black"
        />
        <path
          d="M55.1724 37.9309H51.7241V41.3792H55.1724V37.9309Z"
          fill="black"
        />
        <path
          d="M65.5172 37.9309H62.069V41.3792H65.5172V37.9309Z"
          fill="black"
        />
        <path
          d="M68.9655 37.9309H65.5172V41.3792H68.9655V37.9309Z"
          fill="black"
        />
        <path
          d="M75.8621 37.9309H72.4138V41.3792H75.8621V37.9309Z"
          fill="black"
        />
        <path
          d="M89.6552 37.9309H86.2069V41.3792H89.6552V37.9309Z"
          fill="black"
        />
        <path
          d="M10.3448 41.3792H6.89655V44.8274H10.3448V41.3792Z"
          fill="black"
        />
        <path
          d="M17.2414 41.3792H13.7931V44.8274H17.2414V41.3792Z"
          fill="black"
        />
        <path
          d="M27.5862 41.3792H24.1379V44.8274H27.5862V41.3792Z"
          fill="black"
        />
        <path
          d="M31.0345 41.3792H27.5862V44.8274H31.0345V41.3792Z"
          fill="black"
        />
        <path
          d="M34.4828 41.3792H31.0345V44.8274H34.4828V41.3792Z"
          fill="black"
        />
        <path
          d="M41.3793 41.3792H37.931V44.8274H41.3793V41.3792Z"
          fill="black"
        />
        <path
          d="M44.8275 41.3792H41.3793V44.8274H44.8275V41.3792Z"
          fill="black"
        />
        <path
          d="M48.2759 41.3792H44.8276V44.8274H48.2759V41.3792Z"
          fill="black"
        />
        <path
          d="M55.1724 41.3792H51.7241V44.8274H55.1724V41.3792Z"
          fill="black"
        />
        <path
          d="M65.5172 41.3792H62.069V44.8274H65.5172V41.3792Z"
          fill="black"
        />
        <path
          d="M68.9655 41.3792H65.5172V44.8274H68.9655V41.3792Z"
          fill="black"
        />
        <path
          d="M72.4138 41.3792H68.9655V44.8274H72.4138V41.3792Z"
          fill="black"
        />
        <path
          d="M75.8621 41.3792H72.4138V44.8274H75.8621V41.3792Z"
          fill="black"
        />
        <path
          d="M79.3103 41.3792H75.8621V44.8274H79.3103V41.3792Z"
          fill="black"
        />
        <path
          d="M10.3448 44.8276H6.89655V48.2759H10.3448V44.8276Z"
          fill="black"
        />
        <path
          d="M17.2414 44.8276H13.7931V48.2759H17.2414V44.8276Z"
          fill="black"
        />
        <path
          d="M20.6897 44.8276H17.2414V48.2759H20.6897V44.8276Z"
          fill="black"
        />
        <path
          d="M24.1379 44.8276H20.6896V48.2759H24.1379V44.8276Z"
          fill="black"
        />
        <path
          d="M34.4828 44.8276H31.0345V48.2759H34.4828V44.8276Z"
          fill="black"
        />
        <path
          d="M51.7242 44.8276H48.2759V48.2759H51.7242V44.8276Z"
          fill="black"
        />
        <path
          d="M68.9655 44.8276H65.5172V48.2759H68.9655V44.8276Z"
          fill="black"
        />
        <path
          d="M82.7586 44.8276H79.3104V48.2759H82.7586V44.8276Z"
          fill="black"
        />
        <path
          d="M86.2069 44.8276H82.7586V48.2759H86.2069V44.8276Z"
          fill="black"
        />
        <path
          d="M10.3448 48.2759H6.89655V51.7242H10.3448V48.2759Z"
          fill="black"
        />
        <path
          d="M17.2414 48.2759H13.7931V51.7242H17.2414V48.2759Z"
          fill="black"
        />
        <path
          d="M27.5862 48.2759H24.1379V51.7242H27.5862V48.2759Z"
          fill="black"
        />
        <path
          d="M31.0345 48.2759H27.5862V51.7242H31.0345V48.2759Z"
          fill="black"
        />
        <path
          d="M34.4828 48.2759H31.0345V51.7242H34.4828V48.2759Z"
          fill="black"
        />
        <path
          d="M37.931 48.2759H34.4827V51.7242H37.931V48.2759Z"
          fill="black"
        />
        <path
          d="M51.7242 48.2759H48.2759V51.7242H51.7242V48.2759Z"
          fill="black"
        />
        <path
          d="M58.6207 48.2759H55.1724V51.7242H58.6207V48.2759Z"
          fill="black"
        />
        <path
          d="M62.0689 48.2759H58.6207V51.7242H62.0689V48.2759Z"
          fill="black"
        />
        <path
          d="M65.5172 48.2759H62.069V51.7242H65.5172V48.2759Z"
          fill="black"
        />
        <path
          d="M68.9655 48.2759H65.5172V51.7242H68.9655V48.2759Z"
          fill="black"
        />
        <path
          d="M72.4138 48.2759H68.9655V51.7242H72.4138V48.2759Z"
          fill="black"
        />
        <path
          d="M75.8621 48.2759H72.4138V51.7242H75.8621V48.2759Z"
          fill="black"
        />
        <path
          d="M79.3103 48.2759H75.8621V51.7242H79.3103V48.2759Z"
          fill="black"
        />
        <path
          d="M86.2069 48.2759H82.7586V51.7242H86.2069V48.2759Z"
          fill="black"
        />
        <path
          d="M89.6552 48.2759H86.2069V51.7242H89.6552V48.2759Z"
          fill="black"
        />
        <path
          d="M93.1034 48.2759H89.6552V51.7242H93.1034V48.2759Z"
          fill="black"
        />
        <path
          d="M17.2414 51.7241H13.7931V55.1724H17.2414V51.7241Z"
          fill="black"
        />
        <path
          d="M20.6897 51.7241H17.2414V55.1724H20.6897V51.7241Z"
          fill="black"
        />
        <path
          d="M27.5862 51.7241H24.1379V55.1724H27.5862V51.7241Z"
          fill="black"
        />
        <path
          d="M34.4828 51.7241H31.0345V55.1724H34.4828V51.7241Z"
          fill="black"
        />
        <path
          d="M44.8275 51.7241H41.3793V55.1724H44.8275V51.7241Z"
          fill="black"
        />
        <path
          d="M48.2759 51.7241H44.8276V55.1724H48.2759V51.7241Z"
          fill="black"
        />
        <path
          d="M51.7242 51.7241H48.2759V55.1724H51.7242V51.7241Z"
          fill="black"
        />
        <path
          d="M58.6207 51.7241H55.1724V55.1724H58.6207V51.7241Z"
          fill="black"
        />
        <path
          d="M62.0689 51.7241H58.6207V55.1724H62.0689V51.7241Z"
          fill="black"
        />
        <path
          d="M65.5172 51.7241H62.069V55.1724H65.5172V51.7241Z"
          fill="black"
        />
        <path
          d="M72.4138 51.7241H68.9655V55.1724H72.4138V51.7241Z"
          fill="black"
        />
        <path
          d="M75.8621 51.7241H72.4138V55.1724H75.8621V51.7241Z"
          fill="black"
        />
        <path
          d="M79.3103 51.7241H75.8621V55.1724H79.3103V51.7241Z"
          fill="black"
        />
        <path
          d="M93.1034 51.7241H89.6552V55.1724H93.1034V51.7241Z"
          fill="black"
        />
        <path
          d="M13.7931 55.1724H10.3448V58.6206H13.7931V55.1724Z"
          fill="black"
        />
        <path
          d="M20.6897 55.1724H17.2414V58.6206H20.6897V55.1724Z"
          fill="black"
        />
        <path
          d="M24.1379 55.1724H20.6896V58.6206H24.1379V55.1724Z"
          fill="black"
        />
        <path
          d="M31.0345 55.1724H27.5862V58.6206H31.0345V55.1724Z"
          fill="black"
        />
        <path
          d="M41.3793 55.1724H37.931V58.6206H41.3793V55.1724Z"
          fill="black"
        />
        <path
          d="M48.2759 55.1724H44.8276V58.6206H48.2759V55.1724Z"
          fill="black"
        />
        <path
          d="M55.1724 55.1724H51.7241V58.6206H55.1724V55.1724Z"
          fill="black"
        />
        <path
          d="M65.5172 55.1724H62.069V58.6206H65.5172V55.1724Z"
          fill="black"
        />
        <path
          d="M79.3103 55.1724H75.8621V58.6206H79.3103V55.1724Z"
          fill="black"
        />
        <path
          d="M86.2069 55.1724H82.7586V58.6206H86.2069V55.1724Z"
          fill="black"
        />
        <path
          d="M89.6552 55.1724H86.2069V58.6206H89.6552V55.1724Z"
          fill="black"
        />
        <path
          d="M10.3448 58.6206H6.89655V62.0689H10.3448V58.6206Z"
          fill="black"
        />
        <path
          d="M20.6897 58.6206H17.2414V62.0689H20.6897V58.6206Z"
          fill="black"
        />
        <path
          d="M37.931 58.6206H34.4827V62.0689H37.931V58.6206Z"
          fill="black"
        />
        <path
          d="M41.3793 58.6206H37.931V62.0689H41.3793V58.6206Z"
          fill="black"
        />
        <path
          d="M48.2759 58.6206H44.8276V62.0689H48.2759V58.6206Z"
          fill="black"
        />
        <path
          d="M58.6207 58.6206H55.1724V62.0689H58.6207V58.6206Z"
          fill="black"
        />
        <path
          d="M65.5172 58.6206H62.069V62.0689H65.5172V58.6206Z"
          fill="black"
        />
        <path
          d="M68.9655 58.6206H65.5172V62.0689H68.9655V58.6206Z"
          fill="black"
        />
        <path
          d="M75.8621 58.6206H72.4138V62.0689H75.8621V58.6206Z"
          fill="black"
        />
        <path
          d="M79.3103 58.6206H75.8621V62.0689H79.3103V58.6206Z"
          fill="black"
        />
        <path
          d="M93.1034 58.6206H89.6552V62.0689H93.1034V58.6206Z"
          fill="black"
        />
        <path
          d="M17.2414 62.0688H13.7931V65.5171H17.2414V62.0688Z"
          fill="black"
        />
        <path
          d="M20.6897 62.0688H17.2414V65.5171H20.6897V62.0688Z"
          fill="black"
        />
        <path
          d="M31.0345 62.0688H27.5862V65.5171H31.0345V62.0688Z"
          fill="black"
        />
        <path
          d="M34.4828 62.0688H31.0345V65.5171H34.4828V62.0688Z"
          fill="black"
        />
        <path
          d="M41.3793 62.0688H37.931V65.5171H41.3793V62.0688Z"
          fill="black"
        />
        <path
          d="M44.8275 62.0688H41.3793V65.5171H44.8275V62.0688Z"
          fill="black"
        />
        <path
          d="M51.7242 62.0688H48.2759V65.5171H51.7242V62.0688Z"
          fill="black"
        />
        <path
          d="M58.6207 62.0688H55.1724V65.5171H58.6207V62.0688Z"
          fill="black"
        />
        <path
          d="M62.0689 62.0688H58.6207V65.5171H62.0689V62.0688Z"
          fill="black"
        />
        <path
          d="M65.5172 62.0688H62.069V65.5171H65.5172V62.0688Z"
          fill="black"
        />
        <path
          d="M68.9655 62.0688H65.5172V65.5171H68.9655V62.0688Z"
          fill="black"
        />
        <path
          d="M72.4138 62.0688H68.9655V65.5171H72.4138V62.0688Z"
          fill="black"
        />
        <path
          d="M75.8621 62.0688H72.4138V65.5171H75.8621V62.0688Z"
          fill="black"
        />
        <path
          d="M79.3103 62.0688H75.8621V65.5171H79.3103V62.0688Z"
          fill="black"
        />
        <path
          d="M82.7586 62.0688H79.3104V65.5171H82.7586V62.0688Z"
          fill="black"
        />
        <path
          d="M86.2069 62.0688H82.7586V65.5171H86.2069V62.0688Z"
          fill="black"
        />
        <path
          d="M89.6552 62.0688H86.2069V65.5171H89.6552V62.0688Z"
          fill="black"
        />
        <path
          d="M93.1034 62.0688H89.6552V65.5171H93.1034V62.0688Z"
          fill="black"
        />
        <path
          d="M37.931 65.5171H34.4827V68.9654H37.931V65.5171Z"
          fill="black"
        />
        <path
          d="M58.6207 65.5171H55.1724V68.9654H58.6207V65.5171Z"
          fill="black"
        />
        <path
          d="M65.5172 65.5171H62.069V68.9654H65.5172V65.5171Z"
          fill="black"
        />
        <path
          d="M79.3103 65.5171H75.8621V68.9654H79.3103V65.5171Z"
          fill="black"
        />
        <path
          d="M86.2069 65.5171H82.7586V68.9654H86.2069V65.5171Z"
          fill="black"
        />
        <path
          d="M93.1034 65.5171H89.6552V68.9654H93.1034V65.5171Z"
          fill="black"
        />
        <path
          d="M37.931 68.9653H34.4827V72.4136H37.931V68.9653Z"
          fill="black"
        />
        <path
          d="M44.8275 68.9653H41.3793V72.4136H44.8275V68.9653Z"
          fill="black"
        />
        <path
          d="M55.1724 68.9653H51.7241V72.4136H55.1724V68.9653Z"
          fill="black"
        />
        <path
          d="M58.6207 68.9653H55.1724V72.4136H58.6207V68.9653Z"
          fill="black"
        />
        <path
          d="M65.5172 68.9653H62.069V72.4136H65.5172V68.9653Z"
          fill="black"
        />
        <path
          d="M72.4138 68.9653H68.9655V72.4136H72.4138V68.9653Z"
          fill="black"
        />
        <path
          d="M79.3103 68.9653H75.8621V72.4136H79.3103V68.9653Z"
          fill="black"
        />
        <path
          d="M86.2069 68.9653H82.7586V72.4136H86.2069V68.9653Z"
          fill="black"
        />
        <path
          d="M89.6552 68.9653H86.2069V72.4136H89.6552V68.9653Z"
          fill="black"
        />
        <path
          d="M93.1034 68.9653H89.6552V72.4136H93.1034V68.9653Z"
          fill="black"
        />
        <path
          d="M37.931 72.4138H34.4827V75.8621H37.931V72.4138Z"
          fill="black"
        />
        <path
          d="M48.2759 72.4138H44.8276V75.8621H48.2759V72.4138Z"
          fill="black"
        />
        <path
          d="M55.1724 72.4138H51.7241V75.8621H55.1724V72.4138Z"
          fill="black"
        />
        <path
          d="M58.6207 72.4138H55.1724V75.8621H58.6207V72.4138Z"
          fill="black"
        />
        <path
          d="M65.5172 72.4138H62.069V75.8621H65.5172V72.4138Z"
          fill="black"
        />
        <path
          d="M79.3103 72.4138H75.8621V75.8621H79.3103V72.4138Z"
          fill="black"
        />
        <path
          d="M93.1034 72.4138H89.6552V75.8621H93.1034V72.4138Z"
          fill="black"
        />
        <path
          d="M41.3793 75.8621H37.931V79.3103H41.3793V75.8621Z"
          fill="black"
        />
        <path
          d="M55.1724 75.8621H51.7241V79.3103H55.1724V75.8621Z"
          fill="black"
        />
        <path
          d="M58.6207 75.8621H55.1724V79.3103H58.6207V75.8621Z"
          fill="black"
        />
        <path
          d="M65.5172 75.8621H62.069V79.3103H65.5172V75.8621Z"
          fill="black"
        />
        <path
          d="M68.9655 75.8621H65.5172V79.3103H68.9655V75.8621Z"
          fill="black"
        />
        <path
          d="M72.4138 75.8621H68.9655V79.3103H72.4138V75.8621Z"
          fill="black"
        />
        <path
          d="M75.8621 75.8621H72.4138V79.3103H75.8621V75.8621Z"
          fill="black"
        />
        <path
          d="M79.3103 75.8621H75.8621V79.3103H79.3103V75.8621Z"
          fill="black"
        />
        <path
          d="M82.7586 75.8621H79.3104V79.3103H82.7586V75.8621Z"
          fill="black"
        />
        <path
          d="M37.931 79.3103H34.4827V82.7586H37.931V79.3103Z"
          fill="black"
        />
        <path
          d="M41.3793 79.3103H37.931V82.7586H41.3793V79.3103Z"
          fill="black"
        />
        <path
          d="M62.0689 79.3103H58.6207V82.7586H62.0689V79.3103Z"
          fill="black"
        />
        <path
          d="M68.9655 79.3103H65.5172V82.7586H68.9655V79.3103Z"
          fill="black"
        />
        <path
          d="M72.4138 79.3103H68.9655V82.7586H72.4138V79.3103Z"
          fill="black"
        />
        <path
          d="M79.3103 79.3103H75.8621V82.7586H79.3103V79.3103Z"
          fill="black"
        />
        <path
          d="M82.7586 79.3103H79.3104V82.7586H82.7586V79.3103Z"
          fill="black"
        />
        <path
          d="M86.2069 79.3103H82.7586V82.7586H86.2069V79.3103Z"
          fill="black"
        />
        <path
          d="M89.6552 79.3103H86.2069V82.7586H89.6552V79.3103Z"
          fill="black"
        />
        <path
          d="M93.1034 79.3103H89.6552V82.7586H93.1034V79.3103Z"
          fill="black"
        />
        <path
          d="M37.931 82.7585H34.4827V86.2068H37.931V82.7585Z"
          fill="black"
        />
        <path
          d="M41.3793 82.7585H37.931V86.2068H41.3793V82.7585Z"
          fill="black"
        />
        <path
          d="M58.6207 82.7585H55.1724V86.2068H58.6207V82.7585Z"
          fill="black"
        />
        <path
          d="M62.0689 82.7585H58.6207V86.2068H62.0689V82.7585Z"
          fill="black"
        />
        <path
          d="M68.9655 82.7585H65.5172V86.2068H68.9655V82.7585Z"
          fill="black"
        />
        <path
          d="M72.4138 82.7585H68.9655V86.2068H72.4138V82.7585Z"
          fill="black"
        />
        <path
          d="M79.3103 82.7585H75.8621V86.2068H79.3103V82.7585Z"
          fill="black"
        />
        <path
          d="M86.2069 82.7585H82.7586V86.2068H86.2069V82.7585Z"
          fill="black"
        />
        <path
          d="M89.6552 82.7585H86.2069V86.2068H89.6552V82.7585Z"
          fill="black"
        />
        <path
          d="M41.3793 86.2068H37.931V89.6551H41.3793V86.2068Z"
          fill="black"
        />
        <path
          d="M48.2759 86.2068H44.8276V89.6551H48.2759V86.2068Z"
          fill="black"
        />
        <path
          d="M51.7242 86.2068H48.2759V89.6551H51.7242V86.2068Z"
          fill="black"
        />
        <path
          d="M55.1724 86.2068H51.7241V89.6551H55.1724V86.2068Z"
          fill="black"
        />
        <path
          d="M58.6207 86.2068H55.1724V89.6551H58.6207V86.2068Z"
          fill="black"
        />
        <path
          d="M62.0689 86.2068H58.6207V89.6551H62.0689V86.2068Z"
          fill="black"
        />
        <path
          d="M68.9655 86.2068H65.5172V89.6551H68.9655V86.2068Z"
          fill="black"
        />
        <path
          d="M72.4138 86.2068H68.9655V89.6551H72.4138V86.2068Z"
          fill="black"
        />
        <path
          d="M79.3103 86.2068H75.8621V89.6551H79.3103V86.2068Z"
          fill="black"
        />
        <path
          d="M86.2069 86.2068H82.7586V89.6551H86.2069V86.2068Z"
          fill="black"
        />
        <path d="M37.931 89.655H34.4827V93.1033H37.931V89.655Z" fill="black" />
        <path d="M41.3793 89.655H37.931V93.1033H41.3793V89.655Z" fill="black" />
        <path
          d="M44.8275 89.655H41.3793V93.1033H44.8275V89.655Z"
          fill="black"
        />
        <path
          d="M48.2759 89.655H44.8276V93.1033H48.2759V89.655Z"
          fill="black"
        />
        <path
          d="M68.9655 89.655H65.5172V93.1033H68.9655V89.655Z"
          fill="black"
        />
        <path
          d="M75.8621 89.655H72.4138V93.1033H75.8621V89.655Z"
          fill="black"
        />
        <path
          d="M79.3103 89.655H75.8621V93.1033H79.3103V89.655Z"
          fill="black"
        />
        <path
          d="M82.7586 89.655H79.3104V93.1033H82.7586V89.655Z"
          fill="black"
        />
        <path
          d="M86.2069 89.655H82.7586V93.1033H86.2069V89.655Z"
          fill="black"
        />
        <path
          d="M89.6552 89.655H86.2069V93.1033H89.6552V89.655Z"
          fill="black"
        />
        <path
          d="M93.1034 89.655H89.6552V93.1033H93.1034V89.655Z"
          fill="black"
        />
        <path
          d="M27.4138 6.89648H10.5172H6.89655V10.5172V27.4137V31.0344H10.5172H27.4138H31.0345V27.4137V10.5172V6.89648H27.4138ZM27.4138 27.4137H10.5172V10.5172H27.4138V27.4137Z"
          fill="black"
        />
        <path
          d="M89.4828 6.89648H72.5862H68.9655V10.5172V27.4137V31.0344H72.5862H89.4828H93.1034V27.4137V10.5172V6.89648H89.4828ZM89.4828 27.4137H72.5862V10.5172H89.4828V27.4137Z"
          fill="black"
        />
        <path
          d="M27.4138 68.9653H10.5172H6.89655V72.586V89.4826V93.1033H10.5172H27.4138H31.0345V89.4826V72.586V68.9653H27.4138ZM27.4138 89.4826H10.5172V72.586H27.4138V89.4826Z"
          fill="black"
        />
        <path
          d="M24.1379 13.793H13.7931V24.1378H24.1379V13.793Z"
          fill="black"
        />
        <path
          d="M86.2069 13.793H75.8621V24.1378H86.2069V13.793Z"
          fill="black"
        />
        <path
          d="M24.1379 75.8621H13.7931V86.2069H24.1379V75.8621Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_4107_3775">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default QrCodeIcon
