import { Button, Skeleton } from "antd"
import JKArrowLeftIcon from "assets/icons/JKArrowLeftIcon"
import { useRouter } from "next/router"
import React from "react"
import { layoutLang } from "utils/language/layout"
import { QuizStateModel } from "../utils/models/quizStateModel"
import { useGetNewPaymentMethods } from "pages/apartments/services/queries"
import { itemStyle } from "../Quiz"
import { createArrayWithDan } from "../utils/helpers"

interface IProps {
  quizState: QuizStateModel
  setQuizState: React.Dispatch<React.SetStateAction<QuizStateModel>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const QuizComponent3: React.FC<IProps> = ({
  page,
  quizState,
  setPage,
  setQuizState,
}) => {
  const { locale } = useRouter()
  const { data, isLoading } = useGetNewPaymentMethods()

  const finalPaymentData = data?.concat({
    id: -1,
    isTop: false,
    name: { uz: "Boshqa", ru: "Другой" },
  })

  return (
    <div className="sm:w-[600px] w-full sm:h-auto h-full flex flex-col justify-between pt-8 pb-16 sm:py-0">
      <div className="min-h-[305px]">
        <p className="text-[#98A2B3] text-base font-medium mb-2">{`${page}/${quizState?.payment_method_id === 2 ? 6 : 5
          } ${layoutLang[String(locale)]["savol"]}`}</p>
        <h2 className="text-[#344054] text-2xl font-medium">
          {layoutLang[String(locale)]["uy_joy_xarid_qilish_usuli"]}
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-5">
          {isLoading
            ? createArrayWithDan(3)?.map((_, key) => (
              <Skeleton.Input
                active
                className="!w-full !h-[47px] rounded-xl"
                key={key}
              />
            ))
            : finalPaymentData?.map((item) => (
              <div
                key={item?.id}
                className={`${quizState?.payment_method_id === item?.id
                    ? "bg-[#EEFBF6]"
                    : ""
                  } ${quizState?.payment_method_id === item?.id
                    ? "!border-[#0DC683]"
                    : ""
                  } ${itemStyle}`}
                onClick={() =>
                  item?.id === quizState?.payment_method_id
                    ? setQuizState((state) => ({
                      ...state,
                      payment_method_id: undefined,
                    }))
                    : setQuizState((state) => ({
                      ...state,
                      payment_method_id: item?.id,
                    }))
                }
              >
                <span>{item?.name[locale as keyof typeof item.name]}</span>
                {item?.isTop && (
                  <div className="bg-[#0DC683] px-2 py-0.5 rounded-2xl text-[#FFFFFF] text-xs font-medium">
                    {layoutLang[String(locale)]["TOP"]}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
      <div className="flex items-center gap-3 w-full mt-[68px]">
        <div
          onClick={() => setPage(page - 1)}
          className="h-[47px] flex items-center justify-center rounded-xl bg-[#F2F4F7] gap-2 cursor-pointer px-5 select-none text-base"
        >
          <JKArrowLeftIcon fill="#475467" width={18} height={14} />
          <span className="text-base text-[#475467] font-medium">
            {layoutLang[String(locale)]["ortga"]}
          </span>
        </div>
        <Button
          type="primary"
          onClick={() => setPage(page + 1)}
          className="w-full h-[47px] rounded-xl flex items-center justify-center text-base"
          disabled={!quizState?.payment_method_id}
        >
          {layoutLang[String(locale)]["keyingisi"]}
        </Button>
      </div>
    </div>
  )
}

export default QuizComponent3
