import { Spin } from "antd"
import Complex1Icon from "assets/icons/Complex1Icon"
import LocomotiveIcon from "assets/icons/LocomotiveIcon"
import RegCheckIcon from "assets/icons/RegCheckIcon"
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import { useRouter } from "next/router"
import { useGetComplexFilteres } from "pages/main-layout/components/region-city/services/queries"
import {
  FilterComplexType,
  FilterNearestPlasesType,
  MetroLinesType,
} from "pages/main-layout/components/region-city/utils/models/ComplexFilterModel"
import React from "react"
import { filterReducerActions } from "store/reducers/filterReducer"
import { isEmptyArr } from "utils/helpers/isEmptyArr"

const MetroItems = ({ value }: { value: string }) => {
  const dispatch = useAppDispatch()
  const { locale } = useRouter()
  const { setComplexes, setMetroLines, setNearestPlaces } = filterReducerActions
  const { complexes, metro_lines, nearest_places } = useAppSelector(
    (state) => state.filterReducer
  )
  const { data, isLoading } = useGetComplexFilteres(value)

  const handleSetComplexes = (value: FilterComplexType) => {
    if (complexes) {
      if (complexes?.some((item) => item?.id === value?.id)) {
        const newData = complexes?.filter((item) => item?.id !== value?.id)
        dispatch(setComplexes(newData))
      } else {
        dispatch(setComplexes([...complexes, value]))
      }
    } else {
      dispatch(setComplexes([value]))
    }
  }
  const handleSetMetroLines = (value: MetroLinesType) => {
    if (metro_lines) {
      if (metro_lines?.some((item) => item?.id === value?.id)) {
        const newData = metro_lines?.filter((item) => item?.id !== value?.id)
        dispatch(setMetroLines(newData))
      } else {
        dispatch(setMetroLines([...metro_lines, value]))
      }
    } else {
      dispatch(setMetroLines([value]))
    }
  }
  const handleSetNearestPlaces = (value: FilterNearestPlasesType) => {
    if (nearest_places) {
      if (nearest_places?.some((item) => item?.id === value?.id)) {
        const newData = nearest_places?.filter((item) => item?.id !== value?.id)
        dispatch(setNearestPlaces(newData))
      } else {
        dispatch(setNearestPlaces([...nearest_places, value]))
      }
    } else {
      dispatch(setNearestPlaces([value]))
    }
  }
  return (
    <Spin spinning={isLoading}>
      <div
        className="flex flex-col gap-4 mobileTopFilter md:max-h-[240px] overflow-auto"
        style={{ height: "calc(100vh - 240px)" }}
      >
        {!isEmptyArr(data?.complexes) && (
          <div className="flex flex-col gap-3 bg-white p-3 pb-4 rounded-xl">
            {data?.complexes?.map((item) => (
              <div
                key={item?.id}
                className="flex items-center justify-between pb-3 last:pb-0 border-b border-solid border-[#EAECF0] last:border-b-0 cursor-pointer"
                onClick={() => handleSetComplexes(item)}
              >
                <div className="flex items-center gap-4">
                  <Complex1Icon />
                  <div className="flex flex-col">
                    <span className="text-[#344054] font-medium">
                      {item?.name}
                    </span>
                    <span className="text-[#667085] text-xs">
                      {`${item?.district?.name[
                        locale as keyof typeof item.district.name
                        ]
                        }, ${item?.district?.city?.name[
                        locale as keyof typeof item.district.city.name
                        ]
                        }`}
                    </span>
                  </div>
                </div>
                {complexes?.some((elem) => elem?.id === item?.id) && (
                  <RegCheckIcon />
                )}
              </div>
            ))}
          </div>
        )}
        {!isEmptyArr(data?.metro_lines) && (
          <div className="flex flex-col gap-3 bg-white p-3 pb-4 rounded-xl">
            {data?.metro_lines?.map((item) => (
              <div
                key={item?.id}
                className="flex items-center justify-between pb-3 last:pb-0 border-b border-solid border-[#EAECF0] last:border-b-0 cursor-pointer"
                onClick={() => handleSetMetroLines(item)}
              >
                <div className="flex items-center gap-4">
                  <LocomotiveIcon fill={item?.color} />
                  <span className="text-[#344054] font-medium">
                    {item?.name[locale as keyof typeof item.name]}
                  </span>
                </div>
                {metro_lines?.some((elem) => elem?.id === item?.id) && (
                  <RegCheckIcon />
                )}
              </div>
            ))}
          </div>
        )}
        {!isEmptyArr(data?.nearest_places) && (
          <div className="flex flex-col gap-3 bg-white p-3 pb-4 rounded-xl">
            {data?.nearest_places?.map((item) => (
              <div
                key={item?.id}
                className="flex items-center justify-between pb-3 last:pb-0 border-b border-solid border-[#EAECF0] last:border-b-0 cursor-pointer"
                onClick={() => handleSetNearestPlaces(item)}
              >
                <div className="flex items-center gap-4">
                  <LocomotiveIcon fill={item?.metro_line?.color} />
                  <div className="flex flex-col">
                    <span className="text-[#344054] font-medium">
                      {item?.name[locale as keyof typeof item.name]}
                    </span>
                    <span className="text-[#667085] text-xs">
                      {
                        item?.metro_line?.name[
                        locale as keyof typeof item.metro_line.name
                        ]
                      }
                    </span>
                  </div>
                </div>
                {nearest_places?.some((elem) => elem?.id === item?.id) && (
                  <RegCheckIcon />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </Spin>
  )
}

export default MetroItems
