import { useMutation } from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import { errorHandler } from "../../../utils/helpers/errorHandler";
import { endpoints } from "../utils/constants/endpoints";

// call back
export function useCallBack(afterClose: () => void) {
  return useMutation(
    async (data: any) => {
      const res = await $api.post(endpoints.CALL_BACK, data);
      return res.data;
    },
    {
      onSuccess: () => {
        // showMessage(res?.messages, messageTypes.SUCCESS)
        afterClose();
      },
      onError: errorHandler
    }
  );
}
