import { Dropdown } from "antd"
import React, { useState } from "react"

import style from "./headerDropdown.module.scss"
import { layoutLang } from "../../../../../utils/language/layout"
import { useRouter } from "next/router"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/reduxHooks"
import { localeReducerActions } from "../../../../../store/reducers/localeReducer"
import UzFlagIcon from "assets/icons/UzFlagIcon"
import RuFlagIcon from "assets/icons/RuFlagIcon"
import Link from "next/link"

interface IProps {
  type: "lang" | "currency";
}

const HeaderDropDown: React.FC<IProps> = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { lang, currency } = useAppSelector((state) => state.localeReducer)
  const { changeLanguage, changeCurrency } =
    localeReducerActions
  const [visible, setVisible] = useState(false)

  // useEffect(() => {
  //   fetch(`https://cbu.uz/ru/arkhiv-kursov-valyut/json/`)
  //     .then((res) => res.json())
  //     .then((res: any[]) => {
  //       dispatch(changeUsdValue(res?.find((item) => item?.Ccy === "USD")?.Rate))
  //     })

  //   dispatch(changeLanguage(router?.locale === RU ? RU : UZ))
  // }, [])

  const handleChangeLang = (language: string) => {
    dispatch(changeLanguage(language));
    // setVisible(false)
  };

  const handleChangeCurrency = (cur: string) => {
    dispatch(changeCurrency(cur));
  };

  return (
    <Dropdown
      placement="bottomRight"
      open={visible}
      trigger={["click", "hover"]}
      mouseEnterDelay={1000}
      mouseLeaveDelay={0.3}
      overlayClassName={style.overlayClassDrop}
      onOpenChange={() => setVisible(!visible)}
      overlay={
        <div
          className="flex select-none flex-col gap-6 rounded-lg border border-solid border-[#EAECF0] bg-white px-4 py-[15px]"
          style={{
            boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814"
          }}
        >
          <div className="flex flex-col gap-3">
            <span className="text-base font-medium text-[#101828]">{layoutLang[String(router.locale)]["til"]}</span>
            <div className="flex items-center gap-2">
              <Link
                className={`flex items-center gap-3 px-4 py-2.5 border-[1.5px] border-solid  cursor-pointer relative rounded-xl ${lang === "uz"
                  ? "bg-[#F3FCF9] border-[#2BCD93]"
                  : "bg-[#F2F4F7] border-[#F9FAFB]"
                  }`}
                onClick={() => handleChangeLang("uz")}
                href={router?.asPath}
                locale="uz"
              >
                <UzFlagIcon />
                <span className={`text-sm font-medium ${lang === "uz" ? "text-[#0DC683]" : "text-[#1D2939]"}`}>
                  O‘zbekcha
                </span>
              </Link>
              <Link
                className={`flex items-center gap-3 px-4 py-2.5 border-[1.5px] border-solid  cursor-pointer relative rounded-xl ${lang === "ru"
                  ? "bg-[#F3FCF9] border-[#2BCD93]"
                  : "bg-[#F2F4F7] border-[#F9FAFB]"
                  }`}
                onClick={() => handleChangeLang("ru")}
                href={router?.asPath}
                locale="ru"
              >
                <RuFlagIcon />
                <span className={`text-sm font-medium ${lang === "ru" ? "text-[#0DC683]" : "text-[#1D2939]"}`}>
                  Русский
                </span>
              </Link>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span className="text-base font-medium text-[#101828]">{layoutLang[String(router.locale)]["valyuta"]}</span>
            <div className="flex items-center gap-2">
              <div
                className={`flex items-center justify-center gap-3 px-4 py-2.5 border-[1.5px] border-solid  cursor-pointer relative rounded-xl w-[50%] ${currency === "uzs"
                  ? "bg-[#F3FCF9] border-[#2BCD93]"
                  : "bg-[#F2F4F7] border-[#F9FAFB]"
                  }`}
                onClick={() => {
                  handleChangeCurrency("uzs");
                }}
              >
                <span
                  className={`min-w-max text-sm font-medium ${
                    currency === "uzs" ? "text-[#0DC683]" : "text-[#1D2939]"
                  }`}
                >
                  {layoutLang[String(router.locale)]["ozbek_somi"]}
                </span>
              </div>
              <div
                className={`flex items-center justify-center gap-3 px-4 py-2.5 border-[1.5px] border-solid  cursor-pointer relative rounded-xl w-[50%] ${currency === "usd"
                  ? "bg-[#F3FCF9] border-[#2BCD93]"
                  : "bg-[#F2F4F7] border-[#F9FAFB]"
                  }`}
                onClick={() => {
                  handleChangeCurrency("usd");
                }}
              >
                <span
                  className={`min-w-max text-sm font-medium ${
                    currency === "usd" ? "text-[#0DC683]" : "text-[#1D2939]"
                  }`}
                >
                  {layoutLang[String(router.locale)]["aqsh_dollari"]}
                </span>
              </div>
            </div>
          </div>
        </div>
      }
    >
      <div
        className={`flex h-[47px] cursor-pointer items-center gap-2.5 rounded-2xl border border-solid border-[#EAECF0] px-4 text-base font-semibold text-[#475467]`}
        onClick={() => setVisible(!visible)}
      >
        {router?.locale === "uz" ? <UzFlagIcon width={26} height={18} /> : <RuFlagIcon width={26} height={18} />}
        {` |  ${currency?.toUpperCase()}`}
      </div>
    </Dropdown>
  );
};

export default HeaderDropDown;
