const BusIcon = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.57895 6.1579H1.36842V2.73684H9.57895M8.55263 10.2632C8.28044 10.2632 8.01939 10.155 7.82692 9.96256C7.63445 9.77009 7.52632 9.50904 7.52632 9.23684C7.52632 8.96465 7.63445 8.7036 7.82692 8.51113C8.01939 8.31866 8.28044 8.21053 8.55263 8.21053C8.82483 8.21053 9.08587 8.31866 9.27835 8.51113C9.47082 8.7036 9.57895 8.96465 9.57895 9.23684C9.57895 9.50904 9.47082 9.77009 9.27835 9.96256C9.08587 10.155 8.82483 10.2632 8.55263 10.2632ZM2.39474 10.2632C2.12254 10.2632 1.86149 10.155 1.66902 9.96256C1.47655 9.77009 1.36842 9.50904 1.36842 9.23684C1.36842 8.96465 1.47655 8.7036 1.66902 8.51113C1.86149 8.31866 2.12254 8.21053 2.39474 8.21053C2.66693 8.21053 2.92798 8.31866 3.12045 8.51113C3.31292 8.7036 3.42105 8.96465 3.42105 9.23684C3.42105 9.50904 3.31292 9.77009 3.12045 9.96256C2.92798 10.155 2.66693 10.2632 2.39474 10.2632ZM0 9.57895C0 10.1811 0.266842 10.7216 0.684211 11.0979V12.3158C0.684211 12.4973 0.756297 12.6713 0.884611 12.7996C1.01293 12.9279 1.18696 13 1.36842 13H2.05263C2.2341 13 2.40813 12.9279 2.53644 12.7996C2.66476 12.6713 2.73684 12.4973 2.73684 12.3158V11.6316H8.21053V12.3158C8.21053 12.4973 8.28261 12.6713 8.41093 12.7996C8.53924 12.9279 8.71327 13 8.89474 13H9.57895C9.76041 13 9.93444 12.9279 10.0628 12.7996C10.1911 12.6713 10.2632 12.4973 10.2632 12.3158V11.0979C10.6805 10.7216 10.9474 10.1811 10.9474 9.57895V2.73684C10.9474 0.342105 8.4979 0 5.47368 0C2.44947 0 0 0.342105 0 2.73684V9.57895Z"
        fill="#98A2B3"
      />
    </svg>
  )
}

export default BusIcon
