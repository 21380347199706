import React, { useEffect } from "react"
import styles from "./filterDesktop.module.scss"
import { Button } from "antd"
import SearchIcon from "../../assets/icons/SearchIcon"
import FilterDesktopModal from "./filter-desktop-modal/FilterDesktopModal"
import FilterRoomModal from "./filter-room-modal/FilterRoomModal"
import FilterPriceModal from "./filter-price-modal/FilterPriceModal"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import { useRouter } from "next/router"
import { LOCATION } from "../../utils/constants/queryParamsKeys"
import { useTranslations } from "next-intl"
import { favouritesReducerActions } from "../../store/reducers/favouritesReducer"
import qs from "query-string"
import { clearObj } from "../../utils/helpers/clearObj"
import MobileFilterIcon from "assets/icons/MobileFilterIcon"
import FilterMetro from "pages/main/components/filter-metro/FilterMetro"
import { isEmptyArr } from "utils/helpers/isEmptyArr"

const FilterDesktop = () => {
  const dispatch = useAppDispatch()
  const t = useTranslations("body.Top")
  const { push, query: getQuery, pathname } = useRouter()
  const filter = useAppSelector((state) => state.favouritesReducer.filter)
  const { setChangeComplexName, setOpenMapDrawer, setStudio } =
    favouritesReducerActions
  const location = String(getQuery?.location).split("&")[0]
  const viewType = pathname.includes("karta")

  const { complexes, metro_lines, nearest_places } = useAppSelector(
    (state) => state.filterReducer
  )

  const complex_ids =
    complexes && !isEmptyArr(complexes)
      ? complexes?.map((item) => item?.id)
      : null
  const metro_ids =
    metro_lines && !isEmptyArr(metro_lines)
      ? metro_lines?.map((item) => item?.id)
      : null
  const nearest_place_ids =
    nearest_places && !isEmptyArr(nearest_places)
      ? nearest_places?.map((item) => item?.id)
      : null

  const query = {
    ...(clearObj(qs.parse(getQuery?.location as any)) as any),
    location,
  }

  useEffect(() => {
    if (getQuery?.complex_name) {
      dispatch(setChangeComplexName(String(getQuery?.complex_name)))
    }
    if (getQuery?.rooms_count) {
      if (Array.isArray(getQuery?.rooms_count)) {
        dispatch(setStudio(getQuery?.rooms_count))
      } else {
        dispatch(setStudio([getQuery?.rooms_count]))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuery])

  const onFinish = () => {
    const newValues = {
      ...filter,
      ...getQuery,
      min_total_price: filter?.min_total_price,
      max_total_price: filter?.max_total_price,
      min_prepayment: filter?.min_prepayment,
      max_prepayment: filter?.max_prepayment,
      min_price: filter?.min_price,
      max_price: filter?.max_price,
      complex_name: filter?.complex_name,
      rooms_count: filter?.rooms_count,
      complex_ids: complex_ids,
      metro_ids: metro_ids,
      nearest_place_ids: nearest_place_ids,
    }

    let key: keyof typeof newValues
    const updatedFields = {
      ...newValues,
    }

    for (key in updatedFields) {
      !updatedFields[key] && delete updatedFields[key]
      updatedFields[key] === "undefined" && delete updatedFields[key]
    }

    const getNewQuery = viewType
      ? updatedFields
      : {
        ...updatedFields,
        [LOCATION]: query[LOCATION]?.toString()?.split("&")[0],
      }

    push({
      query: getNewQuery,
    })
  }

  const filterLength = Object.keys(query)?.includes("location")
    ? Object.keys(getQuery)?.length - 1
    : Object.keys(getQuery)?.length

  return (

    <div
      className="flex items-center justify-between max-w-[1620px] mx-auto px-4 py-3.5 bg-white rounded-[20px]"
      onClick={() => {
        dispatch(setOpenMapDrawer({ id: null, open: false }))
      }}>
      <div className="flex items-center gap-4 w-[70%]">
        <FilterRoomModal />
        <FilterPriceModal />
        <FilterMetro type="header" />
      </div>
      <div className="flex items-center justify-end gap-4 w-[20%]">
        <FilterDesktopModal>
          <div
            className={filterLength > 0 ? styles.activeFilter : 'flex items-center h-10 gap-3 bg-[#F2F4F7] rounded-xl pl-3 pr-4 cursor-pointer'}
          >
            <MobileFilterIcon />
            <span className="text-[#344054] text-[15px] font-medium">{t("Фильтр")}</span>
          </div>
        </FilterDesktopModal>
        <Button
          size="large"
          onClick={onFinish}
          className="flex items-center rounded-xl gap-1"
          type="primary"
          icon={<SearchIcon size="20" color="#fff" />}
        >
          <span>{t("Найти")}</span>
        </Button>
      </div>
    </div>
  )
}

export default FilterDesktop
