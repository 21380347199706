const ExpoLogoIcon = () => {
  return (
    <svg
      width="277"
      height="37"
      viewBox="0 0 277 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_896_421)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.59766 32.1494C3.59766 30.5427 4.91295 29.2402 6.53545 29.2402H28.0793C29.7018 29.2402 31.017 30.5427 31.017 32.1494C31.017 33.7561 29.7018 35.0585 28.0793 35.0585H6.53545C4.91295 35.0585 3.59766 33.7561 3.59766 32.1494Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4645 2.80282C16.6055 1.65427 18.4554 1.65427 19.5964 2.80282L34.2052 17.5081C35.3462 18.6567 35.3462 20.5189 34.2052 21.6674C33.0642 22.8159 31.2141 22.8159 30.0731 21.6674L17.5304 9.04175L4.98773 21.6674C3.84671 22.8159 1.99677 22.8159 0.855758 21.6674C-0.285253 20.5189 -0.285253 18.6567 0.855758 17.5081L15.4645 2.80282Z"
          fill="white"
        />
        <path
          d="M22.4749 21.4102C22.4749 24.0056 20.2612 26.1096 17.5304 26.1096C14.7997 26.1096 12.5859 24.0056 12.5859 21.4102C12.5859 18.8149 14.7997 16.7109 17.5304 16.7109C20.2612 16.7109 22.4749 18.8149 22.4749 21.4102Z"
          fill="white"
        />
        <path
          d="M52.4609 7.3418H49.1055V21.3021C49.1055 25.9405 52.4309 29.2217 57.8535 29.2217C63.2762 29.2217 66.6016 25.9405 66.6016 21.3021V7.3418H63.2612V21.0187C63.2612 24.0911 61.2989 26.2537 57.8535 26.2537C54.4082 26.2537 52.4609 24.0911 52.4609 21.0187V7.3418ZM71.7546 34.7104C75.0352 34.7104 76.5781 33.4874 77.9262 29.6842L83.8431 13.1735H80.4277L76.4283 25.9405H76.3833L72.3838 13.1735H68.8785L74.5707 28.8788C74.5707 28.8936 74.2862 29.8184 74.2862 29.8481C73.7918 31.459 72.9979 32.0854 71.4849 32.0854C71.2153 32.0854 70.6761 32.0854 70.4364 32.0257V34.6358C70.6761 34.6805 71.4999 34.7104 71.7546 34.7104ZM85.461 17.6181C85.461 19.8106 86.899 21.2871 89.7452 21.9732L92.5613 22.6593C94.2091 23.0769 94.8082 23.6288 94.8082 24.628C94.8082 25.8958 93.6099 26.7012 91.6626 26.7012C89.6852 26.7012 88.5168 25.866 88.2621 24.3596H85.0565C85.3112 27.2381 87.7828 29.1621 91.5127 29.1621C95.3775 29.1621 98.0588 27.1934 98.0588 24.3298C98.0588 22.0179 96.8604 20.8248 93.6848 20.0641L91.0333 19.4525C89.4006 19.0499 88.6217 18.3936 88.6217 17.4242C88.6217 16.1863 89.7901 15.3361 91.5427 15.3361C93.3552 15.3361 94.5086 16.216 94.6584 17.633H97.6993C97.5944 14.8439 95.1378 12.8901 91.6325 12.8901C87.9475 12.8901 85.461 14.8141 85.461 17.6181ZM107.346 29.1621C111.855 29.1621 114.821 26.0748 114.821 21.0187C114.821 15.9775 111.84 12.8901 107.346 12.8901C102.852 12.8901 99.8713 15.9775 99.8713 21.0187C99.8713 26.0748 102.837 29.1621 107.346 29.1621ZM107.346 26.5072C104.815 26.5072 103.167 24.5087 103.167 21.0187C103.167 17.5435 104.815 15.5449 107.346 15.5449C109.878 15.5449 111.525 17.5435 111.525 21.0187C111.525 24.5087 109.893 26.5072 107.346 26.5072ZM118.266 9.4597V13.1735H115.975V15.7239H118.266V24.7324C118.266 27.7601 119.509 28.9682 122.7 28.9682C123.42 28.9682 124.139 28.9085 124.498 28.8191V26.2687C124.289 26.3134 123.764 26.3433 123.434 26.3433C122.116 26.3433 121.517 25.7317 121.517 24.3745V15.7239H124.513V13.1735H121.517V9.4597H118.266Z"
          fill="white"
        />
      </g>
      <rect x="158" y="4.5" width="1" height="28" fill="white" />
      <path
        d="M191 32.2366V4.69663H203.376V8.84463H197.086V15.6786H201.88V19.8946H197.086V28.1226H203.444V32.2366H191Z"
        fill="white"
      />
      <path
        d="M212.327 32.2366L217.869 17.6166L212.497 4.69663H217.189L220.759 13.2306L223.887 4.69663H228.239L222.935 18.5686L228.647 32.2366H223.955L220.045 22.9886L216.645 32.2366H212.327Z"
        fill="white"
      />
      <path
        d="M239.188 32.2366V4.69663H245.784C247.438 4.69663 248.742 5.00263 249.694 5.61463C250.646 6.22663 251.314 7.07663 251.7 8.16463C252.108 9.25263 252.312 10.5333 252.312 12.0066C252.312 13.2986 252.096 14.5 251.666 15.6106C251.258 16.6986 250.566 17.5713 249.592 18.2286C248.64 18.886 247.382 19.2146 245.818 19.2146H241.772V32.2366H239.188ZM241.772 17.2766H245.07C246.18 17.2766 247.076 17.118 247.756 16.8006C248.458 16.4606 248.968 15.9053 249.286 15.1346C249.603 14.364 249.762 13.3213 249.762 12.0066C249.762 10.6013 249.626 9.51329 249.354 8.74263C249.082 7.94929 248.606 7.40529 247.926 7.11063C247.268 6.79329 246.328 6.63463 245.104 6.63463H241.772V17.2766Z"
        fill="white"
      />
      <path
        d="M269.801 32.6106C268.011 32.6106 266.617 32.2593 265.619 31.5566C264.645 30.8313 263.965 29.8566 263.579 28.6326C263.194 27.386 263.001 25.9806 263.001 24.4166V12.3806C263.001 10.8166 263.205 9.43396 263.613 8.23263C264.021 7.03129 264.713 6.09063 265.687 5.41063C266.685 4.73063 268.056 4.39062 269.801 4.39062C271.547 4.39062 272.907 4.73063 273.881 5.41063C274.879 6.09063 275.581 7.03129 275.989 8.23263C276.397 9.43396 276.601 10.8166 276.601 12.3806V24.4506C276.601 26.0146 276.409 27.4086 276.023 28.6326C275.638 29.8566 274.947 30.8313 273.949 31.5566C272.975 32.2593 271.592 32.6106 269.801 32.6106ZM269.801 31.0806C271.116 31.0806 272.125 30.82 272.827 30.2986C273.553 29.7773 274.051 29.052 274.323 28.1226C274.595 27.1706 274.731 26.0713 274.731 24.8246V12.1086C274.731 10.862 274.595 9.77396 274.323 8.84463C274.051 7.91529 273.564 7.20129 272.861 6.70263C272.159 6.18129 271.139 5.92063 269.801 5.92063C268.487 5.92063 267.467 6.18129 266.741 6.70263C266.039 7.20129 265.551 7.91529 265.279 8.84463C265.007 9.77396 264.871 10.862 264.871 12.1086V24.8246C264.871 26.0713 265.007 27.1706 265.279 28.1226C265.551 29.052 266.039 29.7773 266.741 30.2986C267.467 30.82 268.487 31.0806 269.801 31.0806Z"
        fill="white"
      />
      <defs>
        <clipPath id="clip0_896_421">
          <rect
            width="126"
            height="36"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ExpoLogoIcon
