const FourthSmileIcon = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9937 1.9918C28.1971 1.95155 36.0263 9.42033 35.9999 19.0527C35.9742 28.4486 28.3797 36.0407 18.9337 36.0079C9.41709 35.9759 1.98811 28.246 1.98584 19.0468C1.98334 9.59203 9.55944 1.9918 18.9937 1.9918Z"
        fill="#FECA00"
      />
      <path
        d="M26.536 16.9899C28.2529 16.9912 29.6441 18.3829 29.6385 20.0937C29.6328 21.8045 28.2327 23.2026 26.5294 23.203C24.8062 23.203 23.4142 21.8032 23.419 20.0736C23.4235 18.3551 24.8048 16.9885 26.536 16.9899Z"
        fill="#171615"
      />
      <path
        d="M11.4725 16.9899C13.2027 16.9899 14.5688 18.3544 14.5691 20.0833C14.5691 21.8179 13.1717 23.2262 11.4582 23.2003C9.52589 23.1713 8.33323 21.5528 8.36792 20.0545C8.4076 18.3498 9.75059 16.9899 11.4725 16.9899Z"
        fill="#171615"
      />
      <path
        d="M18.8502 27.9868C17.3154 27.9288 15.8375 27.4224 14.7392 26.0739C14.4778 25.7548 14.2795 25.389 14.1546 24.9958C14.066 24.7167 14.18 24.4561 14.4229 24.3382C14.6657 24.2203 14.9364 24.2953 15.1031 24.5398C15.8239 25.6029 16.8742 26.1304 18.0988 26.3279C19.4409 26.5443 20.7158 26.33 21.8722 25.5743C22.262 25.3181 22.5981 24.9883 22.8617 24.6033C23.0769 24.2928 23.3406 24.2076 23.612 24.3575C23.8655 24.4974 23.9376 24.7625 23.8051 25.1058C23.3866 26.1944 22.5839 26.9119 21.5629 27.4124C20.7537 27.8095 19.8907 27.9737 18.8502 27.9868Z"
        fill="#171615"
      />
    </svg>
  )
}

export default FourthSmileIcon
