import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Rate, Input } from "antd";
import { layoutLang } from "utils/language/layout";
import { useRouter } from "next/router";
import FirstSmileIcon from "assets/icons/first-smile-icon";
import SecondSmileIcon from "assets/icons/second-smile-icon";
import ThirdSmileIcon from "assets/icons/third-smile-icon";
import FourthSmileIcon from "assets/icons/fourth-smile-icon";
import FifthSmileIcon from "assets/icons/fifth-smile-icon";
import { useGiveUserFeedback } from "pages/main-layout/services/mutations";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { recaptchaKey } from "utils/constants/recaptchaKey";

const { useForm, Item, useWatch } = Form;
const { TextArea } = Input;

const UserFeedbackModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [form] = useForm();
  const { locale } = useRouter();
  const [fullUrl, setFullUrl] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)
  const [recaptchaToken, setRecapchaToken] = useState("")


  const onClose = () => {
    setOpen(false);
    form.resetFields();
    setRecapchaToken('')
  };

  const mutation = useGiveUserFeedback(locale!, onClose);

  const rating = useWatch("rating", form);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setFullUrl(window.location.href);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleHuman = (token: string | null) => {
    const values = {
      rating: form.getFieldValue("rating"),
      comment: form.getFieldValue("comment"),
    }

    if (token) {
      setRecapchaToken(token)
      mutation
        .mutate({
          ...values,
          url: fullUrl!,
          recaptchaToken: token
        })
    }
  }




  const onFinish = (values: { rating: number; comment: string }) => {
    if (recaptchaToken) {
      const reqBody = Object.entries(values)
        ?.filter(el => el)
        ?.reduce((result: Record<string, string | number | string[] | number[] | null>, [key, value]) => {
          result[key] = value;
          return result;
        }, {});

      mutation.mutate({ ...reqBody, url: fullUrl, recaptchaToken } as { rating: number; comment: string; url: string, recaptchaToken: string });
    } else {
      recaptchaRef.current?.execute()
    }
  };

  const smileIcons: Record<string, React.ReactNode> = {
    1: <FirstSmileIcon />,
    2: <SecondSmileIcon />,
    3: <ThirdSmileIcon />,
    4: <FourthSmileIcon />,
    5: <FifthSmileIcon />
  };

  const smileTexts: Record<string, string> = {
    1:
      locale === "uz"
        ? "Iltimos, nima qilishimiz kerakligini maslahat bering?"
        : "Посоветуйте, что мы должны сделать лучше?",
    2:
      locale === "uz"
        ? "Iltimos, nima qilishimiz kerakligini maslahat bering?"
        : "Посоветуйте, что мы должны сделать лучше?",
    3:
      locale === "uz"
        ? "Biz nima qilishimiz kerakligini yaxshilash uchun nima tavsiya qilasiz?"
        : "Посоветуйте, что мы должны сделать лучше?",
    4:
      locale === "uz"
        ? "Uni qanday yaxshilashimiz mumkinligini maslahat bera olasizmi?"
        : "Посоветуйте, как бы мы могли его улучшить?",
    5:
      locale === "uz"
        ? "Rahmat! ❤️\nUni yanada yaxshilash uchun nima qilishimiz mumkinligini maslahat bera olasizmi?"
        : "Спасибо! ❤️\nПосоветуйте, как бы мы могли сделать его ещё лучше?"
  };


  return (
    <>
      {!open && (
        <div className="fixed bottom-0 right-0 z-[100000] hidden md:block">
          <Button className="rounded-t-lg" type="primary" onClick={handleOpen}>
            {layoutLang[String(locale)]["user_feedback_btn"]}
          </Button>
        </div>
      )}

      <Modal centered open={open} className="[&_.ant-modal-content]:rounded-lg" footer={null} onCancel={onClose}>
        <Form form={form} onFinish={onFinish} className="mt-5 w-full" layout="vertical">
          <div className="flex flex-col items-center justify-center gap-3">
            <span className="text-base font-semibold text-gray-900">{layoutLang[String(locale)]["user_feedback"]}</span>
            <Item name="rating">
              <Rate
                character={({ index = 0 }) => {
                  const iconIndex = index + 1;
                  const isSelected = iconIndex === rating;
                  return (
                    <div
                      style={{
                        transform: isSelected ? "scale(1.2)" : "scale(1)",
                        color: isSelected ? "#fadb14" : "inherit",
                        transition: "transform 0.3s, color 0.3s",
                        margin: "0 20px"
                      }}
                    >
                      {smileIcons[iconIndex]}
                    </div>
                  );
                }}
              />
            </Item>
          </div>
          {rating ? (
            <div className="flex flex-col gap-1">
              <Item
                name="comment"
                label={<span className="text-base font-semibold text-gray-900">{smileTexts[rating]}</span>}
              >
                <TextArea className="w-full rounded-xl border-gray-50 bg-gray-100 px-4 py-[10px]" />
              </Item>

              <div className="w-full flex items-center justify-center">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  style={{ display: "none" }}
                  size="invisible"
                  sitekey={recaptchaKey}
                  onChange={handleHuman}
                />
                <Button
                  className="h-[44px] w-[50%] rounded-lg"
                  type="primary"
                  htmlType="submit"
                  loading={mutation.isLoading}
                >
                  {layoutLang[String(locale)]["Saqlash"]}
                </Button>
              </div>
            </div>
          ) : null}
        </Form>
      </Modal>
    </>
  );
};

export default UserFeedbackModal;
