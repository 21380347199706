import React from "react"
import MiniCloseIcon from "assets/icons/MiniCloseIcon"
import { Grid, Modal } from "antd"
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import { favouritesReducerActions } from "store/reducers/favouritesReducer"

import uysotCoin from "../../../../assets/images/uysot-coin.png"
import Image from "next/image"

import styles from "./index.module.scss"
import { userReducerActions } from "store/reducers/userReducer"
import { localStorageKeys } from "utils/constants/localStorageKeys"
import { useGetUyotCoin } from "pages/main-layout/services/queries"
import { isBrowser } from "utils/helpers/isBrowser"
import UysotCoinContent from "./uysot-coin-content"

const UysotCoinModal = () => {
  const { data } = useGetUyotCoin()
  const { setCoinVisible } = favouritesReducerActions
  const { setVisibleLoginModal } = userReducerActions
  const { xl, md } = Grid.useBreakpoint()
  const dispatch = useAppDispatch()

  const isAuth = isBrowser() && localStorage.getItem(localStorageKeys.IS_AUTH)

  const coinVisible = useAppSelector(
    (state) => state.favouritesReducer.coinVisible
  )

  const coinCounts = data?.coin_conditions?.reduce((prev, acc) => {
    return prev + acc?.count_coin
  }, 0)

  const handleOpenUysotCoin = () => {
    if (isAuth === "true") {
      dispatch(setCoinVisible(true))
    } else {
      dispatch(setVisibleLoginModal(true))
    }
  }

  const handleClose = () => {
    dispatch(setCoinVisible(false))
  }

  return (
    <>
      <div
        className="flex items-center gap-[15px] bg-[#4D6F68] pl-4 rounded-full cursor-pointer xl:w-20 w-[71px] xl:h-[39px] h-8 relative"
        onClick={handleOpenUysotCoin}
      >
        <span className="text-base text-white font-semibold">
          {coinCounts || 0}
        </span>
        <div className="absolute right-[-2px]">
          <Image
            width={xl ? 40 : 34}
            height={xl ? 44 : 37}
            src={uysotCoin}
            alt="err"
          />
        </div>
      </div>
      {md && (
        <Modal
          open={coinVisible}
          onCancel={handleClose}
          footer={false}
          closable={false}
          title={
            <div className="flex items-center justify-between">
              <div className="text-xl font-bold flex items-center gap-1">
                <span className="text-[#1D2939]">UYSOT</span>
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#0DC683] to-[#00A389]">
                  COIN
                </span>
              </div>
              <div
                className="w-[30px] h-[30px] flex items-center justify-center rounded-full bg-[#F2F2F7] cursor-pointer"
                onClick={handleClose}
              >
                <MiniCloseIcon size={11} />
              </div>
            </div>
          }
          className={styles.coinModal}
          centered
        >
          <UysotCoinContent coinCounts={coinCounts} data={data} />
        </Modal>
      )}
    </>
  )
}

export default UysotCoinModal
