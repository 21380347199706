const LogoIcon = () => {
  return (
    <svg
      width="29"
      height="26"
      viewBox="0 0 29 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.272 23.3889C3.272 22.1468 4.33178 21.1399 5.63911 21.1399H22.9979C24.3052 21.1399 25.365 22.1468 25.365 23.3889C25.365 24.631 24.3052 25.6379 22.9979 25.6379H5.63911C4.33178 25.6379 3.272 24.631 3.272 23.3889Z"
        fill="#00A389"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8354 1.02778C13.7548 0.139827 15.2453 0.139827 16.1646 1.02778L27.9356 12.3966C28.8548 13.2846 28.8548 14.7242 27.9356 15.6121C27.0162 16.5001 25.5256 16.5001 24.6062 15.6121L14.5 5.85115L4.39382 15.6121C3.47446 16.5001 1.98388 16.5001 1.06452 15.6121C0.14516 14.7242 0.14516 13.2846 1.06452 12.3966L12.8354 1.02778Z"
        fill="#00A389"
      />
      <path
        d="M18.1068 15.1479C18.1068 17.0772 16.4697 18.6412 14.4503 18.6412C12.4308 18.6412 10.7938 17.0772 10.7938 15.1479C10.7938 13.2187 12.4308 11.6548 14.4503 11.6548C16.4697 11.6548 18.1068 13.2187 18.1068 15.1479Z"
        fill="#FFAB55"
      />
    </svg>
  )
}

export default LogoIcon
