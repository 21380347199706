import { Select, SelectProps, Spin } from "antd"
import Complex1Icon from "assets/icons/Complex1Icon"
import LocomotiveIcon from "assets/icons/LocomotiveIcon"
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import { useRouter } from "next/router"
import { useGetComplexFilteres, useGetQueryFilteres } from "pages/main-layout/components/region-city/services/queries"
import React, { useEffect, useState } from "react"
import { filterReducerActions } from "store/reducers/filterReducer"
import { isEmptyArr } from "utils/helpers/isEmptyArr"

import styles from "./filterMetro.module.scss"
import { layoutLang } from "utils/language/layout"

interface IProps {
  type: "filter" | "header"
}

const FilterMetro: React.FC<IProps> = ({ type }) => {
  const dispatch = useAppDispatch()
  const { locale, query } = useRouter()
  const [value, setValue] = useState("")
  const { data, isLoading, isFetching } = useGetComplexFilteres(value)
  const { setComplexes, setMetroLines, setNearestPlaces } = filterReducerActions

  const complexes = useAppSelector(
    (state) => state.filterReducer.complexes
  )
  const metro_lines = useAppSelector(
    (state) => state.filterReducer.metro_lines
  )
  const nearest_places = useAppSelector(
    (state) => state.filterReducer.nearest_places
  )

  const { data: filters } = useGetQueryFilteres()

  const handleSetComplexes = (id: number) => {
    const value = data?.complexes?.find((item) => item?.id === id)
    if (complexes) {
      if (complexes?.some((item) => item?.id === id)) {
        const newData = complexes?.filter((item) => item?.id !== id)
        dispatch(setComplexes(newData))
      } else {
        dispatch(setComplexes([...complexes, value!]))
      }
    } else {
      dispatch(setComplexes([value!]))
    }
  }
  const handleSetMetroLines = (id: number) => {
    const value = data?.metro_lines?.find((item) => item?.id === id)
    if (metro_lines) {
      if (metro_lines?.some((item) => item?.id === id)) {
        const newData = metro_lines?.filter((item) => item?.id !== id)
        dispatch(setMetroLines(newData))
      } else {
        dispatch(setMetroLines([...metro_lines, value!]))
      }
    } else {
      dispatch(setMetroLines([value!]))
    }
  }
  const handleSetNearestPlaces = (id: number) => {
    const value = data?.nearest_places?.find((item) => item?.id === id)
    if (nearest_places) {
      if (nearest_places?.some((item) => item?.id === id)) {
        const newData = nearest_places?.filter((item) => item?.id !== id)
        dispatch(setNearestPlaces(newData))
      } else {
        dispatch(setNearestPlaces([...nearest_places, value!]))
      }
    } else {
      dispatch(setNearestPlaces([value!]))
    }
  }

  const complexes1 = !isEmptyArr(data?.complexes)
    ? data?.complexes?.concat(filters?.complexes || [])?.map((item) => ({
      id: item?.id,
      label: (
        <div
          className="flex items-center gap-2"
          onClick={() => handleSetComplexes(item?.id)}
        >
          <Complex1Icon />
          <span className="text-[#1D2939] text-[13px]">{item?.name}</span>
        </div>
      ),
      value: `complexes:${item?.id}`,
    }))
    : []

  const metro_lines1 = !isEmptyArr(data?.metro_lines)
    ? data?.metro_lines?.concat(filters?.metro_lines || [])?.map((item) => ({
      id: item?.id,
      label: (
        <div
          className="flex items-center gap-2"
          onClick={() => handleSetMetroLines(item?.id)}
        >
          <LocomotiveIcon fill={item?.color} />
          <span className="text-[#1D2939] text-[13px]">
            {item?.name[locale as keyof typeof item.name]}
          </span>
        </div>
      ),
      value: `metro_lines:${item?.id}`,
    }))
    : []

  const nearest_places1 = !isEmptyArr(data?.nearest_places)
    ? data?.nearest_places?.concat(filters?.nearest_places || [])?.map((item) => ({
      id: item?.id,
      label: (
        <div
          className="flex items-center gap-2"
          onClick={() => handleSetNearestPlaces(item?.id)}
        >
          <LocomotiveIcon fill={item?.metro_line?.color} />
          <span className="text-[#1D2939] text-[13px]">
            {item?.name[locale as keyof typeof item.name]}
          </span>
        </div>
      ),
      value: `nearest_places:${item?.id}`,
    }))
    : []

  const uniqueComplex = complexes1?.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.id === value.id && t?.value === value?.value
    ))
  );
  const uniqueMetroLines = metro_lines1?.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.id === value.id && t?.value === value?.value
    ))
  );
  const uniqueNearestPlaces = nearest_places1?.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.id === value.id && t?.value === value?.value
    ))
  );

  const options: SelectProps["options"] = [
    ...uniqueComplex!,
    ...uniqueMetroLines!,
    ...uniqueNearestPlaces!,
  ]

  const handleDeleteItem = (
    id: number,
    type: "complexes" | "metro_lines" | "nearest_places"
  ) => {
    const newComplexes = complexes?.filter((item) => item?.id !== id)
    const newMetroLines = metro_lines?.filter((item) => item?.id !== id)
    const newNearestPlaces = nearest_places?.filter((item) => item?.id !== id)

    type === "complexes"
      ? dispatch(setComplexes(newComplexes!))
      : type === "metro_lines"
        ? dispatch(setMetroLines(newMetroLines!))
        : dispatch(setNearestPlaces(newNearestPlaces!))
  }

  useEffect(() => {
    if (query?.complex_ids && filters?.complexes) {
      dispatch(setComplexes(filters?.complexes))
    }
    if (query?.complex_ids && filters?.metro_lines) {
      dispatch(setMetroLines(filters?.metro_lines))
    }
    if (query?.nearest_places && filters?.nearest_places) {
      dispatch(setNearestPlaces(filters?.nearest_places))
    }
  }, [query])

  const defValues = [complexes?.map((item) => `complexes:${item?.id}`), metro_lines?.map((item) => `metro_lines:${item?.id}`), nearest_places?.map((item) => `nearest_places:${item?.id}`)]?.flat(Infinity)?.filter((item) => item)



  return (
    <div className={`${styles.filterMetro} ${type === "filter" ? "h-[43px] rounded-[4px]" : 'h-10 rounded-xl'} overflow-auto border border-solid border-[#d9d9d9]`}>
      <Select
        onSearch={(e) => setValue(e)}
        maxTagCount="responsive"
        mode="multiple"
        placeholder={layoutLang[String(locale)]["filter_text"]}
        className="w-full h-full"
        options={options}
        loading={isLoading}
        filterOption={false}
        notFoundContent={isFetching ? <Spin size="small" /> : null}
        onDeselect={(e: any) =>
          handleDeleteItem(Number(e?.split(":")[1]), e?.split(":")[0])
        }
        value={defValues}
      />
    </div>
  )
}

export default FilterMetro
