function TelegramIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#1B815F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.24273 15.8312C11.9071 13.799 15.0173 12.4593 16.5736 11.812C21.0169 9.96384 21.9402 9.64279 22.542 9.63219C22.6744 9.62986 22.9703 9.66266 23.162 9.81821C23.3239 9.94956 23.3684 10.127 23.3897 10.2515C23.4111 10.376 23.4376 10.6597 23.4165 10.8814C23.1757 13.4114 22.1338 19.5509 21.6038 22.3846C21.3795 23.5836 20.9379 23.9856 20.5103 24.0249C19.5812 24.1104 18.8756 23.4109 17.9757 22.821C16.5675 21.8979 15.7719 21.3232 14.405 20.4225C12.8253 19.3815 13.8494 18.8093 14.7496 17.8743C14.9852 17.6296 19.0791 13.9059 19.1583 13.5681C19.1683 13.5258 19.1775 13.3684 19.0839 13.2852C18.9903 13.2021 18.8523 13.2305 18.7526 13.2531C18.6114 13.2852 16.3618 14.7721 12.0037 17.7139C11.3652 18.1523 10.7868 18.366 10.2686 18.3548C9.69731 18.3424 8.59839 18.0318 7.78145 17.7662C6.77945 17.4405 5.98308 17.2683 6.05243 16.7152C6.08855 16.427 6.48531 16.1324 7.24273 15.8312Z"
        fill="white"
      />
    </svg>
  )
}

export default TelegramIcon
