const YouTubeIcon = () => {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.4 17.005C34.4 7.6134 26.7889 0 17.4 0C8.01119 0 0.400024 7.6134 0.400024 17.005C0.400024 26.3966 8.01119 34.01 17.4 34.01C26.7889 34.01 34.4 26.3966 34.4 17.005Z"
        fill="#FF0209"
      />
      <path
        d="M24.616 24.5369C24.1095 24.5919 23.591 24.6014 23.0941 24.6002C19.2177 24.5967 15.3413 24.5931 11.4661 24.5907C10.2464 24.5907 8.91806 24.5513 7.97554 23.7757C6.91356 22.8999 6.69257 21.3811 6.60656 20.0069C6.4871 18.1237 6.47755 16.2346 6.5755 14.3502C6.62926 13.3153 6.72362 12.2554 7.1692 11.3186C7.48935 10.6459 8.02332 10.0412 8.70662 9.72339C9.50101 9.35416 10.3324 9.41988 11.1878 9.41869C13.2353 9.4163 15.2828 9.4151 17.3303 9.41271C19.459 9.41032 21.5889 9.40913 23.7177 9.40674C24.7235 9.40674 25.8034 9.42705 26.6241 10.009C27.6837 10.7594 27.9715 12.1873 28.1041 13.4791C28.349 15.8582 28.3526 18.2623 28.1137 20.6414C28.0146 21.6225 27.8497 22.6597 27.2034 23.4041C26.5631 24.1426 25.6135 24.427 24.6172 24.5357L24.616 24.5369Z"
        fill="white"
      />
      <path
        d="M20.8667 17.0045L15.0897 13.6682V20.3407L20.8667 17.0045Z"
        fill="#FF0209"
      />
    </svg>
  )
}

export default YouTubeIcon
