import { useEffect, useRef } from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { Dropdown, Spin } from "antd"
import { useAppDispatch } from "../../../../../hooks/reduxHooks"
import { userReducerActions } from "../../../../../store/reducers/userReducer"
import { eventTypes } from "../../../../../utils/constants/eventTypes"
import { PHONE_MASK } from "../../../../../utils/constants/inputMasks"
import { localStorageKeys } from "../../../../../utils/constants/localStorageKeys"
import { isBrowser } from "../../../../../utils/helpers/isBrowser"
import { useLogout } from "../../../services/mutations"
import { layoutLang } from "utils/language/layout"
import { useRouter } from "next/router"
import ReactInputMask from "react-input-mask"
import LogoutIcon from "../../../assets/icon/LogoutIcon"
import HeaderDropDown from "../header-dropdown/HeaderDropDown"

import FavouriteIcon from "assets/icons/FavouriteIcon"
import BonusesIcon from "assets/icons/BonusesIcon"
import CustomLink from "common/custom-link"

import style from "./HeaderTop.module.scss"
import UysotCoinModal from "../../uysot-coin/uysot-coin-modal"

function HeaderTop() {
  const logout = useLogout()
  const dispatch = useAppDispatch()
  const { locale, pathname, replace } = useRouter()
  const { setVisibleLoginModal } = userReducerActions
  const setUserAddresBtnRef = useRef<HTMLButtonElement>(null)

  const isAuth = isBrowser() && localStorage.getItem(localStorageKeys.IS_AUTH)
  const phone = isBrowser() && localStorage.getItem("phone")

  // open login modal
  const openLoginModal = () => {
    dispatch(setVisibleLoginModal(true))
  }

  // handle set user address
  const handleSetUserAddress = () => {
    setUserAddresBtnRef.current?.click()
  }

  // add event listener
  useEffect(() => {
    window.addEventListener(eventTypes.CLICK, handleSetUserAddress)

    return () => {
      window.removeEventListener(eventTypes.CLICK, handleSetUserAddress)
    }
  }, [])

  // logout
  const handleLogout = () => {
    logout.mutateAsync().then(() => {
      localStorage.removeItem("token")
    })
    if (pathname.includes("bonuses")) {
      replace("/")
    } else {
      logout.mutate()
    }
  }

  return (
    <div className={style.header_right}>
      <div className="flex items-center gap-4">
        <UysotCoinModal />
        <HeaderDropDown type="lang" />
      </div>
      <>
        {isAuth === "true" ? (
          <div className={style.header_right_login}>
            <ReactInputMask
              mask={PHONE_MASK}
              value={localStorage.getItem(localStorageKeys.PHONE) ?? ""}
              disabled
            />
            <Dropdown
              trigger={["click", "hover"]}
              mouseEnterDelay={1000}
              mouseLeaveDelay={0.3}
              overlay={
                <div
                  className={`bg-white rounded-lg  select-none border border-solid border-[#F9FAFB]`}
                  style={{
                    boxShadow:
                      "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
                  }}
                >
                  <CustomLink
                    href="/favourites"
                    className="flex items-center gap-3 px-4 py-2.5 border-b border-solid border-[#F9FAFB] cursor-pointer relative"
                  >
                    <FavouriteIcon />
                    <p className="text-[#000000D9]">
                      {layoutLang[String(locale)]["Избранное"]}
                    </p>
                  </CustomLink>
                  <CustomLink
                    href="/bonuses"
                    className="flex items-center gap-3 px-4 py-2.5 border-b border-solid border-[#F9FAFB] cursor-pointer relative"
                  >
                    <BonusesIcon />
                    <p className="text-[#000000D9]">
                      {layoutLang[String(locale)]["Bonuslar"]}
                    </p>
                  </CustomLink>
                  <p
                    onClick={handleLogout}
                    aria-hidden
                    className="flex items-center gap-3 px-4 py-2.5 cursor-pointer relative"
                  >
                    {logout.isLoading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 20 }} spin />
                        }
                      />
                    ) : (
                      <LogoutIcon />
                    )}
                    <p>{layoutLang[String(locale)]["Выйти"]}</p>
                  </p>
                </div>
              }
            >
              <div className="ml-4 select-none flex items-center gap-2.5 border border-solid border-[#EAECF0] rounded-2xl h-[47px] px-4 cursor-pointer">
                <p>{phone}</p>
              </div>
            </Dropdown>
          </div>
        ) : (
          <div
            className={`${style.header_right_login} h-[46px] px-6 border border-solid border-[#0DC683] bg-[#0DC68321] text-[#0DC683] text-lg font-semibold rounded-2xl ml-4`}
            onClick={openLoginModal}
          >
            {layoutLang[String(locale)]["login"]}
          </div>
        )}
      </>
    </div>
  )
}

export default HeaderTop
