import dayjs from "dayjs"
import React from "react"

import InstagramIcon from "../../../../assets/icons/InstagramIcon"
import TelegramIcon from "../../../../assets/icons/TelegramIcon"

import style from "./Footer.module.scss"
import { layoutLang } from "../../../../utils/language/layout"
import { useRouter } from "next/router"
import CustomLink from "common/custom-link"

const year = dayjs().year()

function Footer() {
  const { locale } = useRouter()

  return (
    <footer className={style.footer}>
      <div className="wrapper">
        <div className={style.footer_top}>
          <div className={style.footer_top_links}>
            <CustomLink
              href={
                "https://business.uysot.uz/?utm_source=uysot_market&utm_medium=site&utm_campaign=promo"
              }
              target="_blank"
            >
              <span>
                {layoutLang[String(locale)]["Для застройщиков"]}
              </span>
            </CustomLink>
            <CustomLink href={"/faq"}>
              <span>{layoutLang[String(locale)].Tez_tez_beriladigan_savollar}</span>
            </CustomLink>
            <CustomLink
              href={
                "https://docs.google.com/document/d/152DlTJXqCBVfZQFmaKGWBden5-hzEXugRbwWSP92VaA"
              }
              target="_blank"
            >
              <span>
                {layoutLang[String(locale)]["Foydalanish shartlari"]}
              </span>
            </CustomLink>
          </div>
          <div className={style.footer_top_messenger}>
            <CustomLink href={"https://t.me/uysot_uz"} target="_blank" aria-label="telegram url">
              <span>
                <TelegramIcon />
              </span>
            </CustomLink>
            <CustomLink href={"https://www.instagram.com/uysot_uz/"} target="_blank" aria-label="instagram url">
              <span>
                <InstagramIcon />
              </span>
            </CustomLink>
          </div>
        </div>
        <div className={style.footer_bottom}>
          <span>© {year} «Uysot»</span>
          <a href="tel:+998781136350">+998 78 113 63 50</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
