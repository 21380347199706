const RuFlagIcon = ({
  width = 23,
  height = 18,
}: {
  width?: number
  height?: number
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9422_20342)">
        <rect y="0.5" width="23" height="17" rx="3" fill="#1A47B8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 11.8334H23V17.5H0V11.8334Z"
          fill="#F93939"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.5H23V6.16667H0V0.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9422_20342">
          <rect y="0.5" width="23" height="17" rx="3" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RuFlagIcon
