const BadgeIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5891 7.88412C17.0433 8.53542 17.0433 9.46458 17.5891 10.1159C18.4275 11.1158 17.9208 12.6127 16.6314 12.9486C15.791 13.1672 15.2232 13.9185 15.2663 14.754C15.3327 16.0362 14.0068 16.9615 12.7585 16.5046C11.9453 16.207 11.0253 16.4938 10.5498 17.1942C9.81948 18.2686 8.18052 18.2686 7.45025 17.1942C6.97401 16.4938 6.0547 16.207 5.24152 16.5046C3.99323 16.9615 2.66733 16.0362 2.73366 14.754C2.77677 13.9185 2.209 13.1672 1.36863 12.9486C0.0792117 12.6134 -0.427535 11.1158 0.410852 10.1159C0.956732 9.46458 0.956732 8.53542 0.410852 7.88412C-0.427535 6.88423 0.0792117 5.38725 1.36863 5.05141C2.20768 4.83282 2.77611 4.08147 2.733 3.24599C2.66601 1.96378 3.9919 1.03845 5.24086 1.49538C6.05404 1.79299 6.97401 1.50621 7.44958 0.805842C8.17985 -0.268614 9.81882 -0.268614 10.5491 0.805842C11.0253 1.50621 11.9446 1.79299 12.7578 1.49538C14.0061 1.03845 15.332 1.96378 15.2657 3.24599C15.2226 4.08147 15.7903 4.83282 16.6307 5.05141C17.9208 5.38662 18.4269 6.88423 17.5891 7.88412Z"
        fill="#1570EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1119 5.87938C13.4365 6.21855 13.4365 6.76846 13.1119 7.10764L8.34616 12.0862C7.97754 12.4713 7.3799 12.4713 7.01129 12.0862L4.8892 9.86932C4.56452 9.53015 4.56452 8.98024 4.8892 8.64106C5.21387 8.30189 5.74027 8.30189 6.06494 8.64107L7.67872 10.3269L11.9361 5.87938C12.2608 5.54021 12.7872 5.54021 13.1119 5.87938Z"
        fill="white"
      />
    </svg>
  )
}

export default BadgeIcon
