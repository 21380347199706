const ArticleIcon = (svg: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...svg}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.09091 6.13636C4.09091 5.75981 4.39617 5.45455 4.77273 5.45455H15.2273C15.6038 5.45455 15.9091 5.75981 15.9091 6.13636C15.9091 6.51292 15.6038 6.81818 15.2273 6.81818H4.77273C4.39617 6.81818 4.09091 6.51292 4.09091 6.13636Z"
        fill={svg?.fill}
      />
      <path
        d="M4.09091 10.6818C4.09091 10.3053 4.39617 10 4.77273 10H15.2273C15.6038 10 15.9091 10.3053 15.9091 10.6818C15.9091 11.0584 15.6038 11.3636 15.2273 11.3636H4.77273C4.39617 11.3636 4.09091 11.0584 4.09091 10.6818Z"
        fill={svg?.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.79042 0.728544C3.75709 0.211561 4.711 0 6.99241 0H13.0076C15.289 0 16.2429 0.211561 17.2096 0.728544C18.0967 1.20299 18.797 1.90329 19.2715 2.79042C19.7884 3.75709 20 4.71101 20 6.99241V13.0076C20 15.289 19.7884 16.2429 19.2715 17.2096C18.797 18.0967 18.0967 18.797 17.2096 19.2715C16.2429 19.7884 15.289 20 13.0076 20H6.99241C4.71101 20 3.75709 19.7884 2.79042 19.2715C1.90329 18.797 1.20299 18.0967 0.728544 17.2096C0.211561 16.2429 0 15.289 0 13.0076V6.99241C0 4.711 0.211561 3.75709 0.728544 2.79042C1.20299 1.90329 1.90329 1.20299 2.79042 0.728544ZM6.99241 1.36364C4.79717 1.36364 4.10858 1.56999 3.43351 1.93102C2.78402 2.27837 2.27837 2.78402 1.93102 3.43351C1.56999 4.10858 1.36364 4.79717 1.36364 6.99241V13.0076C1.36364 15.2028 1.56999 15.8914 1.93102 16.5665C2.27837 17.216 2.78402 17.7216 3.43351 18.069C4.10858 18.43 4.79717 18.6364 6.99241 18.6364H13.0076C15.2028 18.6364 15.8914 18.43 16.5665 18.069C17.216 17.7216 17.7216 17.216 18.069 16.5665C18.43 15.8914 18.6364 15.2028 18.6364 13.0076V6.99241C18.6364 4.79717 18.43 4.10858 18.069 3.43351C17.7216 2.78402 17.216 2.27837 16.5665 1.93102C15.8914 1.56999 15.2028 1.36364 13.0076 1.36364H6.99241Z"
        fill={svg?.fill}
      />
      <path
        d="M6.36364 15.2273C6.36364 14.8507 6.6689 14.5455 7.04545 14.5455C7.42201 14.5455 7.72727 14.8507 7.72727 15.2273V19.3182C7.72727 19.6947 7.42201 20 7.04545 20C6.6689 20 6.36364 19.6947 6.36364 19.3182V15.2273Z"
        fill={svg?.fill}
      />
      <path
        d="M12.2727 15.2273C12.2727 14.8507 12.578 14.5455 12.9545 14.5455C13.3311 14.5455 13.6364 14.8507 13.6364 15.2273V19.3182C13.6364 19.6947 13.3311 20 12.9545 20C12.578 20 12.2727 19.6947 12.2727 19.3182V15.2273Z"
        fill={svg?.fill}
      />
    </svg>
  )
}

export default ArticleIcon
