import { useRouter } from "next/router"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/reduxHooks"
import { userReducerActions } from "../../../../../store/reducers/userReducer"
import { rootPaths } from "../../../../../utils/constants/rootPaths"
import { menuLinks } from "../../../utils/constants/menuLinks"
import { layoutLang } from "../../../../../utils/language/layout"

import FavouriteIcon from "../../../../../assets/icons/FavouriteIcon"
import UysotIcon from "../../../../../assets/icons/UysotIcon"
import HamburgerIcon from "../../../assets/icon/HamburgerIcon"

import styles from "./HeaderBottom.module.scss"
import UzFlagIcon from "assets/icons/UzFlagIcon"
import React from "react"
import RuFlagIcon from "assets/icons/RuFlagIcon"
import HeaderTop from "../top/HeaderTop"
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper"
import { favouritesReducerActions } from "store/reducers/favouritesReducer"
import UysotCoinModal from "../../uysot-coin/uysot-coin-modal"
import CustomLink from "common/custom-link"

const HeaderBottom: React.FC = () => {
  const dispatch = useAppDispatch()
  const { pathname, locale } = useRouter()

  const { toggleVisibleMobileMenu, setChangeMenu, setOpenLang } =
    userReducerActions
  const { setChangeFilterType } = favouritesReducerActions
  const { menuIcon } = useAppSelector((state) => state.userReducer)
  const { lang } = useAppSelector((state) => state.localeReducer)
  const { location: currentLocation } = useAppSelector(
    (state) => state.favouritesReducer
  )

  const city_id = currentLocation?.regionValue
    ? currentLocation?.regionValue?.city?.id
      ? currentLocation?.regionValue?.city?.id
      : currentLocation?.regionValue?.district?.city?.id
    : undefined

  const district_id =
    currentLocation?.regionValue && currentLocation?.regionValue?.district
      ? currentLocation?.regionValue?.district?.id
      : undefined

  const location =
    currentLocation?.regionValue && currentLocation?.regionValue?.city
      ? currentLocation?.regionValue?.city?.url
      : currentLocation?.regionValue?.district?.city?.url || "gorod-tashkent"


  // handle open mobile menu
  const handleOpenMobilMenu = () => {
    dispatch(toggleVisibleMobileMenu())
    dispatch(setChangeMenu(!menuIcon))
  }

  const handleOpenLang = () => {
    dispatch(setOpenLang(true))
  }

  return (
    <div className={`${styles.header_bottom} flex flex-col w-full gap-6`}>
      <div className="w-full relative z-[101] bg-white">
        <div className={`${styles.header_bottom_content} justify-between max-w-[1620px] mx-auto`}>
          <div className="flex items-center gap-7 h-full">
            <div className={styles.header_bottom_content_left}>
              <NonSSRWrapper>
                <div className="w-[42px] h-[42px] border border-solid border-[#EAECF0] rounded-xl xl:hidden flex items-center justify-center">
                  <HamburgerIcon
                    className={`${styles.header_bottom_content_left_menuIcon} ${styles.hamburger}`}
                    onClickFunc={handleOpenMobilMenu}
                  />
                </div>
              </NonSSRWrapper>
              <CustomLink href={rootPaths.INDEX} className={styles.root_link}>
                <UysotIcon />
              </CustomLink>
            </div>
            <nav
              className="xl:flex hidden"
              aria-hidden
              suppressHydrationWarning
            >
              <ul className={styles.header_bottom_content_center}>
                {menuLinks(location, city_id, district_id).map(
                  (item, index) => (
                    <li key={index}>
                      <CustomLink
                        href={item.path}
                        className={
                          pathname.includes(item?.basePath) ? styles.active : ""
                        }
                        onClick={() => {
                          item?.path?.includes("kvartiri")
                            ? dispatch(setChangeFilterType("apartment"))
                            : item?.path?.includes("novostroyki")
                              ? dispatch(setChangeFilterType("complex"))
                              : ""
                        }}
                      >
                        <span
                          onClick={() => {
                            item?.path?.includes("kvartiri")
                              ? dispatch(setChangeFilterType("apartment"))
                              : item?.path?.includes("novostroyki")
                                ? dispatch(setChangeFilterType("complex"))
                                : ""
                          }}
                        >
                          {
                            layoutLang[String(locale) as any]![
                            String(item?.label)
                            ]
                          }
                        </span>
                      </CustomLink>
                    </li>
                  )
                )}
              </ul>
            </nav>
          </div>
          <div className="xl:block hidden">
            <HeaderTop />
          </div>
          <NonSSRWrapper>
            <div
              className={`${styles.header_bottom_content_right} flex xl:hidden  items-center gap-3`}
              aria-hidden
              suppressHydrationWarning
            >
              <CustomLink href={rootPaths.FAVOURITES}>
                <span className="border border-solid border-[#EAECF0] h-[42px] w-[42px] flex items-center justify-center rounded-xl">
                  <FavouriteIcon width={16} height={16} />
                </span>
              </CustomLink>
              <div
                className="flex items-center h-[37px] gap-2.5 p-2.5 border border-solid border-[#EAECF0] rounded-xl cursor-pointer"
                onClick={handleOpenLang}
              >
                {lang === "uz" ? (
                  <>
                    <UzFlagIcon />
                    <span className="text-[#475467] font-semibold">O‘z</span>
                  </>
                ) : (
                  <>
                    <RuFlagIcon />
                    <span className="text-[#475467] font-semibold">РУ</span>
                  </>
                )}
              </div>
              <UysotCoinModal />
            </div>
          </NonSSRWrapper>
        </div>
      </div>
    </div>
  )
}

export default HeaderBottom
