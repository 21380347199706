import { useMutation } from "@tanstack/react-query"
import $api from "services/RequestService"
import { message } from "antd"
import { SendAppModel } from "../utils/models/sendAppModel"
import { endPoints } from "../utils/constants/endPoints"

export function useSendApplication() {
  return useMutation({
    mutationFn: async (data: SendAppModel) => {
      const res = await $api.post(endPoints.SEND_APP, data)
      return res.data
    },
    onError: () => {
      message.error("Error:")
    },
  })
}
