const ParkIcon1 = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="14" height="13" rx="2" fill="#0FA64C" />
      <path
        d="M7.86667 11H6.13333V9.2H3L4.77778 6.5H3.88889L7 2L10.1111 6.5H9.22222L11 9.2H7.86667V11Z"
        fill="white"
      />
    </svg>
  )
}

export default ParkIcon1
