const CurrencyIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 15.1667C8.5 16.4553 9.54467 17.5 10.8333 17.5H13C14.3807 17.5 15.5 16.3807 15.5 15C15.5 13.6193 14.3807 12.5 13 12.5H11C9.61929 12.5 8.5 11.3807 8.5 10C8.5 8.61929 9.61929 7.5 11 7.5H13.1667C14.4553 7.5 15.5 8.54467 15.5 9.83333M12 6V7.5M12 17.5V19M22 12.5C22 18.0228 17.5228 22.5 12 22.5C6.47715 22.5 2 18.0228 2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5Z"
        stroke="#98A2B3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CurrencyIcon
